import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';

export const SORT_MAP = {
    POWERLEVEL: {
        enum: 'POWERLEVEL',
        index: 'power',
        label: 'Power',
        field: 'powerLevelRating',
    },
    SALT: {
        enum: 'SALT',
        index: 'salt',
        label: 'Salt',
        field: 'saltRating',
    },
    COUNT: {
        enum: 'COUNT',
        index: 'count',
        label: 'Count',
        field: 'count',
    },
    DIRECTION: {
        ASC: 'asc',
        DESC: 'desc',
    },
};

export const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: {
        listItems: [],
        nextCursor: null,
        isFetching: false,
        loadingState: `idle`,
        filters: {
            query: '',
            queryType: '',
            range: {
                high: 10,
                low: 1,
            },
            colorFilters: '',
        },
        sortBy: SORT_MAP.POWERLEVEL,
        sortDirection: SORT_MAP.DIRECTION.DESC,
        selectedListKey: null,
        selectedListIndex: -1,
    },
    reducers: {
        resetLeaderboardItems: (state, action) => {
            state.listItems = [];
            state.selectedListKey = null;
            state.selectedListIndex = -1;
        },
        setNextCursor: (state, action) => {
            state.nextCursor = action.payload;
        },
        addNewItems: (state, action) => {
            const { cursor, isReload } = action.payload;

            state.nextCursor = cursor || null;

            let i = -1;
            const list = action.payload.items
                .filter((item) => {
                    return item !== null;
                })
                .filter((item) => {
                    if (!item.hasOwnProperty('_cardCount')) {
                        return true;
                    }

                    return item._cardCount === 100;
                })
                .map((item) => {
                    i += 1;

                    return {
                        ...item,
                        key: item?.id,
                        storeKey: 'leaderboard',
                        indexPrefix: 'leaderboard_',
                        index: i + state?.listItems?.length || 0,
                    };
                });

            if (!isReload) {
                state.listItems = state.listItems.concat(list);
            } else {
                state.selectedListKey = null;
                state.selectedListIndex = -1;
                state.listItems = list;
            }

            state.isFetching = false;
            state.loadingState = `idle`;
        },
        updateExistingItem: (state, action) => {
            try {
                const updatedItem = action.payload;
                let found = false;

                state.listItems = state.listItems.map((item) => {
                    if (item?.id === updatedItem?.id) {
                        found = true;
                        return updatedItem;
                    }
                    return item;
                });

                if (!found) {
                    state.listItems = state.listItems.concat([updatedItem]);
                }

                state.listItems = state.listItems.sort((a, b) => {
                    return (
                        parseFloat(b?.[state.result] || b?.[state.sortBy.field]) -
                        parseFloat(a?.[state.result] || a?.[state.sortBy.field])
                    );
                });
            } catch (error) {}
        },
        setLeaderboardIsFetching: (state, action) => {
            state.isFetching = action.payload.isFetching;
            state.loadingState = action.payload.loadingState;
        },
        setLeaderboardFilters: (state, action) => {
            state.filters = action.payload;
        },
        setLeaderboardSortBy: (state, action) => {
            state.sortBy = action?.payload || SORT_MAP.POWERLEVEL;
        },
        setLeaderboardSortDirection: (state, action) => {
            state.sortDirection = action?.payload || SORT_MAP.DIRECTION.DESC;
        },
        setSelectedListKey: (state, action) => {
            state.selectedListKey = action?.payload;
        },
        setSelectedListIndex: (state, action) => {
            state.selectedListIndex = action?.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    resetLeaderboardItems,
    addNewItems,
    setLeaderboardIsFetching,
    updateExistingItem,
    setLeaderboardFilters,
    setLeaderboardSortBy,
    setLeaderboardSortDirection,
    setSelectedListKey,
    setSelectedListIndex,
} = leaderboardSlice.actions;

export const leaderboardFetchFiltered = (filters, sortBy, sortDirection) => (dispatch) => {
    dispatch(setLeaderboardFilters(filters));
    dispatch(
        fetchAll(
            null,
            {
                ...filters,
            },
            true,
            sortBy,
            sortDirection
        )
    );
};

export const fetchAll =
    (cursor, filters, isReload = false, sortBy, sortDirection) =>
    (dispatch) => {
        cursor = cursor !== -1 ? cursor : null;
        cursor = cursor ? `${new URLSearchParams(cursor).toString()}` : null;

        if (isReload) {
            dispatch(resetLeaderboardItems([]));
        }

        dispatch(
            setLeaderboardIsFetching({
                isFetching: true,
                loadingState: cursor ? `loadingMore` : `loading`,
            })
        );

        // console.log(`sortBy: ${JSON.stringify(sortBy)}`);

        DynamoConnector.getList(cursor, 'decks', sortBy?.index || null, filters, sortDirection, (results) => {
            // DynamoConnector.getLeaderboard(cursor, filters, sortBy, (results) => {
            dispatch(
                addNewItems({
                    items: results?.Items || [],
                    cursor: results?.LastEvaluatedKey ? results.LastEvaluatedKey : null,
                    type: null,
                    isReload,
                })
            );
        });
    };

export const addNewDeckToLeaderboard = (deck) => (dispatch) => {
    // prettyPrintJSON(deck);
    // dispatch(addNewItems({
    //   items: [ deck ],
    //   // cursor: results?.lastEvaluatedKey ? results.lastEvaluatedKey : null,
    //   cursor: getNextCursor(),
    //   type: 'new',
    // }));
    dispatch(updateExistingItem(deck));
};

export const handleUpdatedDeck = (deck) => (dispatch) => {
    dispatch(updateExistingItem(deck));
};

export default leaderboardSlice.reducer;
