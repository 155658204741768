import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';
import { SORT_MAP } from './leaderboardSlice';
import { fetchCommanderLeaderboardItems, setCommandersFilters } from './commandersSlice';
import { addNewAuthorsItems, setAuthorsFilters, setAuthorsIsFetching, setAuthorsItems } from './authorsSlice';

export const archetypesSlice = createSlice({
    name: 'archetypes',
    initialState: {
        listItems: [],
        nextCursor: null,
        isFetching: false,
        loadingState: `idle`,
        filters: {
            query: '',
            queryType: '',
            range: {
                high: 10,
                low: 1,
            },
            colorFilters: '',
        },
        sortBy: SORT_MAP.POWERLEVEL,
        sortDirection: SORT_MAP.DIRECTION.DESC,
    },
    reducers: {
        setArchetypesItems: (state, action) => {
            state.listItems = action.payload.map((item) => {
                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'archetypes',
                };
            });
        },
        setArchetypesNextCursor: (state, action) => {
            state.nextCursor = action.payload;
        },
        addNewArchetypesItems: (state, action) => {
            const { cursor, isReload } = action.payload;

            state.nextCursor = cursor || null;
            const newItems = action.payload.items.map((item) => {
                return {
                    ...item,
                    key: item?.id,
                    storeKey: 'archetypes`',
                };
            });

            if (!isReload) {
                state.listItems = state.listItems.concat(newItems);
            } else {
                state.listItems = newItems;
            }

            state.isFetching = false;
            state.loadingState = `idle`;
        },
        setArchetypesIsFetching: (state, action) => {
            state.isFetching = action.payload.isFetching;
            state.loadingState = action.payload.loadingState;
        },
        setArchetypesFilters: (state, action) => {
            state.filters = action.payload;
        },
        setArchetypesSortBy: (state, action) => {
            state.sortBy = action?.payload || SORT_MAP.POWERLEVEL;
        },
        setArchetypeSortDirection: (state, action) => {
            state.sortDirection = action?.payload || SORT_MAP.DIRECTION.DESC;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setArchetypesItems,
    setArchetypesNextCursor,
    addNewArchetypesItems,
    setArchetypesIsFetching,
    setArchetypesFilters,
    setArchetypesSortBy,
    setArchetypeSortDirection,
} = archetypesSlice.actions;

export const fetchArchetypesItems =
    (cursor, isReload = false, sortBy, sortDirection, filters = { archetypes: '' }) =>
    (dispatch) => {
        cursor = cursor !== -1 ? cursor : null;
        cursor = cursor ? `${new URLSearchParams(cursor).toString()}` : null;

        if (isReload) {
            dispatch(setAuthorsItems([]));
        }

        dispatch(
            setAuthorsIsFetching({
                isFetching: true,
                loadingState: cursor ? `loadingMore` : `loading`,
            })
        );

        DynamoConnector.getList(cursor, 'decks', sortBy?.index || null, filters, sortDirection, (results) => {
            // DynamoConnector.getLeaderboard(cursor, filters, sortBy, (results) => {
            dispatch(
                addNewArchetypesItems({
                    items: results?.Items || [],
                    cursor: results?.LastEvaluatedKey ? results.LastEvaluatedKey : null,
                    type: null,
                    isReload,
                })
            );
        });
    };

export default archetypesSlice.reducer;
