import React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@adobe/react-spectrum';

import { BaseDeckPane } from './BaseDeckPane';

export function CombosPane() {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);

    const CATEGORY_CONSTANTS = {
        WINCON: {
            enum: 'WINCON',
            label: 'Wincons',
            shortLabel: 'Wincons',
            field: 'wincon',
            color: '#ff0000',
            subCategories: {},
        },
        ENABLER: {
            enum: 'ENABLER',
            label: 'Enablers',
            shortLabel: 'Enablers',
            field: 'enabler',
            color: '#faed04',
            subCategories: {
                'INFINITE-CAST': {
                    enum: 'INFINITE-CAST',
                    label: 'Cast Triggers',
                    shortLabel: 'Cast Triggers',
                    field: 'infinite-cast',
                    color: '#ff0000',
                },
                'INFINITE-STORMCOUNT': {
                    enum: 'INFINITE-STORMCOUNT',
                    label: 'Storm Triggers',
                    shortLabel: 'Storm',
                    field: 'infinite-stormcount',
                    color: '#ff0000',
                },
                'INFINITE-MANA': {
                    enum: 'INFINITE-MANA',
                    label: 'Mana',
                    shortLabel: 'Mana',
                    field: 'infinite-mana',
                    color: '#ff0000',
                },
                'INFINITE-COUNTERS': {
                    enum: 'INFINITE-COUNTERS',
                    label: 'Counters',
                    shortLabel: 'Counters',
                    field: 'infinite-counters',
                    color: '#ff0000',
                },
                'INFINITE-DRAW': {
                    enum: 'INFINITE-DRAW',
                    label: 'Draw',
                    shortLabel: 'Draw',
                    field: 'infinite-draw',
                    color: '#ff0000',
                },
                'INFINITE-SCRY': {
                    enum: 'INFINITE-SCRY',
                    label: 'Scry',
                    shortLabel: 'Scry',
                    field: 'infinite-scry',
                    color: '#ff0000',
                },
                'INFINITE-LOOT': {
                    enum: 'INFINITE-LOOT',
                    label: 'Loot',
                    shortLabel: 'Loot',
                    field: 'infinite-loot',
                    color: '#ff0000',
                },
                'INFINITE-MILL': {
                    enum: 'INFINITE-MILL',
                    label: 'Mill',
                    shortLabel: 'Mill',
                    field: 'infinite-mill',
                    color: '#ff0000',
                },
                'INFINITE-LIFE': {
                    enum: 'INFINITE-LIFE',
                    label: 'Life',
                    shortLabel: 'Life',
                    field: 'infinite-life',
                    color: '#ff0000',
                },
                'INFINITE-TOKENS': {
                    enum: 'INFINITE-TOKENS',
                    label: 'Tokens',
                    shortLabel: 'Tokens',
                    field: 'infinite-tokens',
                    color: '#ff0000',
                },
                'INFINITE-ETB': {
                    enum: 'INFINITE-ETB',
                    label: 'ETB Triggers',
                    shortLabel: 'ETB',
                    field: 'infinite-etb',
                    color: '#ff0000',
                },
                'INFINITE-LTB': {
                    enum: 'INFINITE-LTB',
                    label: 'LTB Triggers',
                    shortLabel: 'LTB',
                    field: 'infinite-ltb',
                    color: '#ff0000',
                },
                'INFINITE-UNTAP': {
                    enum: 'INFINITE-UNTAP',
                    label: 'Untap',
                    shortLabel: 'Untap',
                    field: 'infinite-untap',
                    color: '#ff0000',
                },
                'INFINITE-LANDFALL': {
                    enum: 'INFINITE-LANDFALL',
                    label: 'Landfall',
                    shortLabel: 'Landfall',
                    field: 'infinite-landfall',
                    color: '#ff0000',
                },
                'EXILE-LIBRARY': {
                    enum: 'EXILE-LIBRARY',
                    label: 'Exile Library',
                    shortLabel: 'Exile Library',
                    field: 'exile-library',
                    color: '#ff0000',
                },
                // UNCATEGORIZED: {
                //     enum: 'UNCATEGORIZED',
                //     label: 'Uncategorized',
                //     shortLabel: 'Uncategorized',
                //     field: 'uncategorized',
                //     color: '#ff0000',
                // },
            },
        },
        LOCK: {
            enum: 'LOCK',
            label: 'Locks',
            shortLabel: 'Locks',
            field: 'lock',
            color: '#F8F8FF',
            subCategories: {},
        },
    };

    const getCategories = () => {
        const list = {};

        deck?.details?.combos?.list?.map((combo) => {
            const category = combo?.type?.toUpperCase();

            const getDisplayItem = () => {
                let names = '';
                for (let i = 0; i < combo?.cards?.length; i++) {
                    if (i > 0) {
                        names = `${names}\n`;
                    }

                    names = `${names}${cards?.[combo?.cards?.[i]]?.name}`;
                }

                // let cardNames = (combo?.cards || []).map((item) => {
                //     return {
                //         ...item,
                //         ...cards?.[item?.id],
                //     };
                // });

                return {
                    id: combo.id,
                    type: 'combo',
                    cards: (combo?.cards || []).map((__card) => {
                        if (cards.hasOwnProperty(__card)) {
                            return cards?.[__card];
                        }

                        let retCard = null;
                        Object.keys(cards || {}).forEach((cardId) => {
                            if (cards[cardId].containerId === __card) {
                                if (cards[cardId].isFrontFace) {
                                    retCard = cards[cardId];
                                } else {
                                    if (!retCard) {
                                        if (new RegExp(`_${__card}`).test(cardId)) {
                                            retCard = cards[cardId];
                                        } else {
                                            if (new RegExp(`${__card}_back`).test(cardId)) {
                                                retCard = cards[cardId];
                                            }
                                        }
                                    }
                                }
                                // if (new RegExp(`^${__card}`).test(cardId)) {
                                //     if (cards[cardId].isFrontFace) {
                                //         retCard = cards[cardId];
                                //     }
                                // } else {
                                //     if (!cards[cardId].isFrontFace) {
                                //
                                //     }
                                // }
                            }
                        });

                        return retCard;
                    }),
                    // score: parseFloat(parseFloat(combo?.score)),
                    href: combo?.spellbookUri || '',
                };
            };

            if (!list?.[category]) {
                list[category] = {
                    label: `${CATEGORY_CONSTANTS?.[category]?.label?.toUpperCase()}`,
                    id: category,
                    count: 0,
                    list: [],
                    color: CATEGORY_CONSTANTS?.[category]?.color || `#FFFFFF`,
                    subCategories: {},
                };
            }

            list[category].list.push(getDisplayItem());
            list[category].count += 1;

            if (category === CATEGORY_CONSTANTS.ENABLER.enum) {
                combo?.categories?.map((subCategory) => {
                    subCategory = subCategory.toUpperCase();

                    if (CATEGORY_CONSTANTS.ENABLER.subCategories.hasOwnProperty(subCategory)) {
                        if (!list?.[category].subCategories?.[subCategory]) {
                            list[category].subCategories[subCategory] = {
                                label: `${CATEGORY_CONSTANTS.ENABLER.subCategories?.[
                                    subCategory
                                ]?.label?.toUpperCase()}`,
                                id: subCategory,
                                count: 0,
                                list: [],
                                parentCategory: category,
                                color: CATEGORY_CONSTANTS.ENABLER.subCategories?.[subCategory]?.color || `#FFFFFF`,
                            };
                        }

                        //TODO: FUUUUCK fix the backend
                        let isFound = false;
                        list[category].subCategories[subCategory].list.forEach((comboItem) => {
                            if (comboItem.id === getDisplayItem().id) {
                                isFound = true;
                            }
                        });

                        if (!isFound) {
                            list[category].subCategories[subCategory].list.push(getDisplayItem());
                            list[category].subCategories[subCategory].count += 1;
                        }
                    }
                });
            }
        });

        return {
            WINCON: list['WINCON'],
            LOCK: list['LOCK'],
            ENABLER: list['ENABLER'],
        };
    };

    return (
        <BaseDeckPane title="COMBOS" fieldName="name" showScore={false} categories={getCategories()} showLinkOut={true}>
            <Flex direction="column">
                <span>
                    All combos are sourced from{' '}
                    <a href={'http://commanderspellbook.com'} target={'_blank'}>
                        Commander Spellbook.
                    </a>
                    Are some of your combos not showing up? If so, this could be due to any of the following:
                    <p />
                    <ul>
                        <li>
                            Your combo is not yet on Commander Spellbook. This is generally because one of the
                            following:
                            <ul>
                                <li>The combo is non-deterministic in nature</li>
                                <li>It has not been added/submitted to their database yet</li>
                            </ul>
                            In either event, I suggest joining their discord and submitting it there. They're a great
                            community.
                            <p />
                        </li>
                        <li>
                            Commndersalt's cache of Commander Spellbook's database is out of sync. I refresh it about
                            once a month, so try checking back soon.
                        </li>
                    </ul>
                </span>
            </Flex>
        </BaseDeckPane>
    );
}
