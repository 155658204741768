import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Text, Flex, ContextualHelp, Heading, Content, SearchField } from '@adobe/react-spectrum';
import './SubmitForm.css';
import Link from '@spectrum-icons/workflow/Link';
import { importDeckList } from '../../data/redux/slices/importSlice';
import { toast } from 'react-toastify';
import { getAnalytics, logEvent } from 'firebase/analytics';
import isMobile from 'ismobilejs';

export function SubmitForm(props) {
    const dispatch = useDispatch();
    const [url, setUrl] = useState(0);
    const flexDirection = isMobile(window.navigator).phone ? 'column' : 'row';

    const urlCleanedValue = url ? url?.trim() : null;
    const isDisabled = !urlCleanedValue;

    const analytics = getAnalytics();

    const handleOnPress = () => {
        if (/archidekt\.com\/sandbox/.test(urlCleanedValue)) {
            toast(`Sandbox URIs not supported`);
        } else if (/archidekt\.com\/search/.test(urlCleanedValue)) {
            toast(`Search URIs not supported`);
        } else if (/mtggoldfish\.com\/articles/.test(urlCleanedValue)) {
            toast(`MTGGoldfish articles not supported`);
        } else if (/\/playtest/.test(urlCleanedValue)) {
            toast(`Playtest URIs not supported`);
        } else {
            if (
                urlCleanedValue.includes(`moxfield.com/`) ||
                urlCleanedValue.includes(`archidekt.com/`) ||
                urlCleanedValue.includes(`tappedout.net/`) ||
                urlCleanedValue.includes(`manabox.app/`) ||
                urlCleanedValue.includes(`mtggoldfish.com/`) ||
                urlCleanedValue.includes(`scryfall.com/`) ||
                urlCleanedValue.includes(`deckstats.net/`) ||
                // urlCleanedValue.includes(`melee.gg/`) ||
                urlCleanedValue.includes(`topdecked.com/`) ||
                urlCleanedValue.includes(`hareruyamtg.com/`)
            ) {
                dispatch(importDeckList(urlCleanedValue));

                logEvent(analytics, 'import_start', { url });

                props?.onStartCallback && props.onStartCallback();
            } else {
                toast(
                    `Sorry, we currently only accept deck lists from Moxfield, Archidekt, Tappedout, Manabox, MtGGoldfish, Scryfall, Deckstats, TopDecked, and Hareruya` //, and Melee`
                );
            }
        }
    };

    return (
        <Flex
            direction={flexDirection}
            alignItems="end"
            rowGap="10px"
            columnGap="25px"
            maxWidth="800px"
            width="calc(100vw - 30px)"
        >
            <Flex direction="column" maxWidth="800px" width="100%" justifyContent="space-around">
                <Flex
                    direction="row"
                    alignItems="end"
                    columnGap="5px"
                    maxWidth="800px"
                    width="100%"
                    UNSAFE_className="submit-header"
                >
                    <Text>Paste a link to your decklist here!</Text>
                    <ContextualHelp variant="info">
                        <Heading>What can I import?</Heading>
                        <Content>
                            <Text>
                                Currently we allow Commander legal decks from:
                                <ul>
                                    <li>moxfield.com</li>
                                    <li>archidekt.com</li>
                                    <li>tappedout.net</li>
                                    <li>manabox.app</li>
                                    <li>mtggoldfish.com</li>
                                    <li>deckstats.net</li>
                                    <li>scryfall.com</li>
                                    <li>topdecked.com</li>
                                    <li>hareruyamtg.com</li>
                                    {/*<li>melee.gg</li>*/}
                                </ul>
                            </Text>
                        </Content>
                    </ContextualHelp>
                </Flex>
                <SearchField
                    icon={<Link />}
                    inputMode="url"
                    onChange={(val) => {
                        setUrl(val);
                    }}
                    onSubmit={() => {
                        handleOnPress();
                    }}
                    width="100%"
                    autoFocus={true}
                />
            </Flex>
            <Button
                variant="negative"
                isDisabled={isDisabled}
                alignSelf="flex-end"
                UNSAFE_className="submit-button"
                onPress={handleOnPress}
            >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Get salty!
            </Button>
        </Flex>
    );
}
