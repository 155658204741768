export const getPrettyLabel = (label) => {
    label = label.toLowerCase();
    label = label === 'fastmana' ? 'fast mana' : label;
    label = label === 'spotremoval' ? 'spot removal' : label;
    label = label === 'cantrip' ? 'draw' : label;
    label = label === 'counters' ? 'counterspells' : label;
    label = label === 'plusoneplusonecounters' ? '+1/+1 COUNTERS' : label;
    label = label === 'extracombat' ? 'EXTRA COMBAT' : label;
    label = label === 'manafixing' ? 'MANA FIXING' : label;
    label = label === 'onering' ? 'ONE RING' : label;

    // power levels
    label = label === 'tutors' ? 'tutor' : label;
    label = label === 'landbase' ? 'manabase' : label;
    label = label === 'winConditions' ? 'win cons' : label;
    label = label === 'combos' ? 'combo' : label;
    label = label === 'answers' ? 'interaction' : label;
    label = label === 'counters' ? 'counterspells' : label;
    label = label === 'wincon' ? 'other' : label;

    // console.log(label)
    label = label?.toUpperCase();

    return label;
};
