import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ActionButton,
    Divider,
    Flex,
    Item,
    Menu,
    MenuTrigger,
    Section,
    Switch,
    Text,
    Checkbox,
    View,
    ProgressCircle,
    TableHeader,
    Column,
    TableBody,
    Row,
    TableView,
    Cell,
} from '@adobe/react-spectrum';

import { fetchUpgradesListByCategory, setUpgradesFetchingTrue } from '../../../data/redux/slices/upgradesSlice';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import { ManaSelector } from '../../filterpanel/ManaSelector';
import { useDebouncedCallback } from 'use-debounce';
import { getAnalytics, logEvent } from 'firebase/analytics';
import styles from './UpgradelizerPane.css';
import { resultsColumns } from '../../resultsListing/columns/ResultsColumns';
import { resultsColumnRenderers } from '../../resultsListing/renderers/ResultsColumnRenderers';
import { resultsCellRenderers } from '../../resultsListing/renderers/ResultsCellRenderers';
import { ResultsListing } from '../../resultsListing/ResultsListing';

export function UpgradelizerPane() {
    const dispatch = useDispatch();
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);
    let upgradesList = useSelector((state) => state.upgrades.list);
    upgradesList = (upgradesList || []).filter((item) => {
        return item && item?.id;
    });
    // const isFetching = useSelector((state) => state.upgrades.isFetching);
    const loadingState = useSelector((state) => state?.upgrades?.loadingState);
    const nextCursor = useSelector((state) => state?.upgrades?.nextCursor);
    const selectedListKey = useSelector((state) => state.upgrades.selectedListKey);
    const selectedListIndex = useSelector((state) => state.upgrades.selectedListIndex);

    const CATEGORY_CONTSTANTS = {
        COUNTERSPELL: {
            enum: 'COUNTERSPELL',
            label: 'Counters',
            shortLabel: 'Counters',
            field: 'counterspell',
            color: '#33ffb1',
            chartIndex: 1,
        },
        CANTRIP: {
            enum: 'CANTRIP',
            label: 'Draw',
            shortLabel: 'Draw',
            field: 'cantrip',
            color: '#008cff',
            chartIndex: 3,
        },
        REMOVAL: {
            enum: 'REMOVAL',
            label: 'Removal',
            shortLabel: 'Removal',
            field: 'removal',
            color: '#931d36',
            chartIndex: 5,
        },
        RAMP: {
            enum: 'RAMP',
            label: 'Ramp',
            shortLabel: 'Ramp',
            field: 'ramp',
            color: '#00a600',
            chartIndex: 6,
        },
        STAX: {
            enum: 'STAX',
            label: 'Stax',
            shortLabel: 'Stax',
            field: 'stax',
            color: '#F8F8FF',
            chartIndex: 9,
        },
        TUTOR: {
            enum: 'TUTOR',
            label: 'Tutors',
            shortLabel: 'Tutors',
            field: 'tutor',
            color: '#0446c5',
            chartIndex: 10,
        },
        FASTMANA: {
            enum: 'FASTMANA',
            label: 'Fastmana',
            shortLabel: 'Fastmana',
            field: 'fastmana',
            color: 'rgb(215,179,0)',
            chartIndex: 12,
        },
        TAXES: {
            enum: 'TAXES',
            label: 'Taxes',
            shortLabel: 'Taxes',
            field: 'taxes',
            color: '#e8f5f5',
            chartIndex: 16,
        },
        GRAVEYARD_HATE: {
            enum: 'GRAVEYARD_HATE',
            label: 'Graveyard Hate',
            shortLabel: 'Graveyard',
            field: 'graveyard',
            color: '#157c1d',
            chartIndex: 19,
        },
    };

    const CATEGORY_SECTION_CONSTANTS = {
        CONSISTENCY: {
            label: 'Consistency',
            enum: 'CONSISTENCY',
            items: [CATEGORY_CONTSTANTS.TUTOR.enum, CATEGORY_CONTSTANTS.CANTRIP.enum],
        },
        EFFICIENCY: {
            label: 'Efficiency',
            enum: 'EFFICIENCY',
            items: [CATEGORY_CONTSTANTS.FASTMANA.enum, CATEGORY_CONTSTANTS.RAMP.enum],
        },
        INTERACTION: {
            label: 'Interaction',
            enum: 'INTERACTION',
            items: [
                CATEGORY_CONTSTANTS.COUNTERSPELL.enum,
                CATEGORY_CONTSTANTS.REMOVAL.enum,
                CATEGORY_CONTSTANTS.GRAVEYARD_HATE.enum,
                CATEGORY_CONTSTANTS.STAX.enum,
                CATEGORY_CONTSTANTS.TAXES.enum,
            ],
        },
    };

    const baseCardTypeList = [
        // '___HEADER',
        'Artifact',
        'Battle',
        'Creature',
        'Enchantment',
        'Instant',
        'Land',
        'Planeswalker',
        'Sorcery',
    ];

    let [categoryId, setCategoryId] = React.useState(CATEGORY_CONTSTANTS.REMOVAL.enum || null);
    let [reservedListSelected, setReservedListSelected] = React.useState(false);
    const [colorFilters, setColorFilters] = useState(deck?.colorIdentity?.toLowerCase() || null);
    let [selectedTypes, setSelectedTypes] = useState(
        new Set(
            baseCardTypeList.map((item) => {
                return item.toUpperCase();
            })
        )
    );

    const getCategoryList = () => {
        const sections = {};

        const retValue = Object.keys(CATEGORY_SECTION_CONSTANTS).map((key) => {
            const item = CATEGORY_SECTION_CONSTANTS[key];
            return {
                name: item.label,
                id: item.enum,
                children: (CATEGORY_SECTION_CONSTANTS[key].items || [])
                    ?.sort((a, b) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                    })
                    ?.map((id) => {
                        return {
                            id: id,
                            name: CATEGORY_CONTSTANTS[id]?.label || '',
                        };
                    }),
            };
        });

        return retValue;
    };

    const categoryIdList = getCategoryList();

    const cardTypeList = baseCardTypeList
        .map((item) => {
            return { name: item, id: item.toUpperCase() };
        })
        ?.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
        });

    const doLoad = (id, reservedList, colors, types, isReload) => {
        if (CATEGORY_CONTSTANTS?.hasOwnProperty(id) && CATEGORY_CONTSTANTS[id]?.hasOwnProperty('field')) {
            // setTimeout(() => {
            dispatch(
                fetchUpgradesListByCategory(
                    deck.id,
                    CATEGORY_CONTSTANTS[id].field,
                    reservedList,
                    colors,
                    [...types] || [],
                    isReload ? null : nextCursor,
                    isReload
                )
            );
            // }, 5000);
        }
    };
    const handleCategoryChange = (id, reservedList, colors, types) => {
        doLoad(id, reservedList, colors, types, true);
        setCategoryId(id);
        setReservedListSelected(reservedList);
        setSelectedTypes(types);
    };

    const handleColorFiltersChange = useDebouncedCallback((evn) => {
        handleCategoryChange(
            categoryId,
            reservedListSelected,
            evn?.colors?.toUpperCase(),
            // selectedTypes.filter((item) => {
            //     return item !== '___HEADER';
            // })
            selectedTypes
        );
        setColorFilters(evn.colors);
        // setChanged(true);
    }, 500);

    const handleTypesChange = (...params) => {
        console.log(`TYPES => ${JSON.stringify(params, null, 4)}`);
    };

    const handleLoadMore = (evn) => {
        // if (
        //     !isFetching &&
        //     loadingState === null //&& //) {
        //     // upgradesList?.length === 0
        // ) {
        if (upgradesList?.length === 0 || nextCursor !== null) {
            doLoad(categoryId, reservedListSelected, colorFilters, selectedTypes, nextCursor === null);
        }
    };

    const getTableHeight = () => {
        return `${window.innerHeight - 215}px`;
    };

    const [tableHeight, setTableHeight] = useState(getTableHeight());

    const resizeHandler = () => {
        setTableHeight(getTableHeight());
    };

    // useEffect(() => {
    //     window.addEventListener('resize', resizeHandler, { passive: true });
    //
    //     return () => {
    //         window.removeEventListener('resize', resizeHandler);
    //     };
    // }, []);

    const disabledColorsList = [['W', 'U', 'B', 'R', 'G'], deck?.colors || []].reduce((p, c) =>
        p.filter((e) => !c.includes(e))
    );

    const fart = useRef(null);

    return (
        // <BaseDeckPane fieldName="name" content={<div />} showScore={true} categories={{}}>
        <Flex direction="column" minHeight={800}>
            <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                <p />
                Feed your deck to the Upgradelizer! (SUPER BETA)
            </span>
            <Text marginTop={10} marginBottom={10}>
                Like seriously... this is ultra beta lol
            </Text>
            <Text marginTop={10}>
                The only cards that we show here are ones that have been used in decks that other people have imported.
                In other words:
                <ul>
                    <li>These results do not include every card ever printed</li>
                    <li>The amount of cards available to search will grow over time as people use the site</li>
                </ul>
            </Text>
            <Divider width="100%" marginBottom={20} size={'S'} />
            <Flex direction="row" justifyContent="center">
                <Flex
                    direction="column"
                    justifyContent="space-around"
                    marginEnd="10px"
                    width={'100%'}
                    marginStart={10}
                    rowGap={10}
                >
                    <Flex direction={'row'} columnGap={20} rowGap={20} wrap>
                        <Flex direction={'column'} flexBasis={350} flexGrow={1} rowGap={10}>
                            <Flex direction={'row'}>
                                <Text minWidth={75} UNSAFE_className={'upgradeMenuBoxLabel'}>
                                    Category:
                                </Text>
                                <MenuTrigger>
                                    <ActionButton
                                        flexBasis={500}
                                        flexGrow={1}
                                        UNSAFE_className={'upgradeMenuBoxActionLabel'}
                                    >
                                        {CATEGORY_CONTSTANTS[categoryId]?.label || ''}
                                    </ActionButton>
                                    <Menu
                                        items={categoryIdList}
                                        selectedKeys={categoryId}
                                        onSelectionChange={(evn) => {
                                            handleCategoryChange(
                                                evn.currentKey,
                                                reservedListSelected,
                                                colorFilters,
                                                selectedTypes
                                            );
                                        }}
                                        selectionMode="single"
                                    >
                                        {(item) => (
                                            <Section items={item.children} title={item.name}>
                                                {/*{(item) => getSymbols(item)}*/}
                                                {(item) => <Item>{item.name}</Item>}
                                            </Section>
                                        )}
                                    </Menu>
                                </MenuTrigger>
                            </Flex>
                            <Flex direction={'row'}>
                                <Text minWidth={75} UNSAFE_className={'upgradeMenuBoxLabel'}>
                                    Card types:
                                </Text>
                                <MenuTrigger>
                                    <ActionButton
                                        flexBasis={500}
                                        flexGrow={1}
                                        UNSAFE_className={'upgradeMenuBoxActionLabel'}
                                    >
                                        Select
                                    </ActionButton>
                                    <Menu
                                        items={cardTypeList}
                                        selectedKeys={selectedTypes}
                                        onSelectionChange={(evn) => {
                                            handleCategoryChange(categoryId, reservedListSelected, colorFilters, evn);
                                        }}
                                        selectionMode={'multiple'}
                                        minWidth={400}
                                    >
                                        {(item) => {
                                            if (item.name !== '___HEADER') {
                                                return <Item>{item.name}</Item>;
                                            } else {
                                                return (
                                                    <Item>
                                                        <View minWidth={400} paddingBottom={20}>
                                                            <Checkbox>test</Checkbox>
                                                        </View>
                                                    </Item>
                                                );
                                            }
                                        }}
                                    </Menu>
                                </MenuTrigger>
                            </Flex>
                        </Flex>
                        <Flex direction={'row'} justifyContent={'center'} maxWidth={400} flexBasis={200} flexGrow={1}>
                            <Checkbox
                                isSelected={reservedListSelected}
                                onChange={(evn) => {
                                    handleCategoryChange(categoryId, evn, colorFilters, selectedTypes);
                                }}
                            >
                                Include Reserved List
                            </Checkbox>
                        </Flex>
                        <Flex direction={'row'} justifyContent={'center'} maxWidth={400} flexBasis={200} flexGrow={1}>
                            <span>
                                <Flex direction="row" columnGap="10px">
                                    <Text UNSAFE_className="filtersSectionLabel">COLOR IDENTITY</Text>
                                </Flex>
                                <ManaSelector
                                    handleColorFilterChange={handleColorFiltersChange}
                                    colorFilters={colorFilters}
                                    disabledColors={disabledColorsList}
                                />
                            </span>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Divider width="100%" marginTop="10px" size={'S'} />

            <Flex direction="column">
                <TableView
                    // selectionMode={'none'}
                    overflowMode={'wrap'}
                    density={'compact'}
                    height={tableHeight}
                    // UNSAFE_style={{
                    //     height: 800,
                    //     minHeight: 800,
                    //     maxHeight: 800,
                    // }}
                    ref={fart}
                    selectedKeys={'all'}
                >
                    <TableHeader columns={resultsColumns.UPGRADES()}>
                        {(column) => (
                            <Column
                                key={column?.uid}
                                align={column?.uid === 'authorAvatarUrl' ? 'start' : 'start'}
                                maxWidth={column?.maxWidth}
                                width={column?.width}
                                minWidth={column?.minWidth}
                            >
                                {resultsColumnRenderers.UPGRADES(column)}
                            </Column>
                        )}
                    </TableHeader>
                    <TableBody items={upgradesList || []} loadingState={loadingState} onLoadMore={handleLoadMore}>
                        {(item) => <Row>{(columnKey) => resultsCellRenderers.UPGRADES(item, columnKey)}</Row>}
                    </TableBody>
                </TableView>
            </Flex>
        </Flex>
    );
}
