import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActionButton,
    Cell,
    Checkbox,
    Flex,
    Item,
    Menu,
    MenuTrigger,
    Section,
    Text,
    View,
} from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './Archetypes.css';
import {
    fetchArchetypesItems,
    setArchetypeSortDirection,
    setArchetypesSortBy,
} from '../../data/redux/slices/archetypesSlice';
import { getGrades } from '../../data/ScoreDisplayUtils';
import { Helmet } from 'react-helmet';
import { ResultsListing } from '../resultsListing/ResultsListing';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import { FilterPanel } from '../filterpanel/FilterPanel';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { resultsColumns } from '../resultsListing/columns/ResultsColumns';
import { resultsColumnRenderers } from '../resultsListing/renderers/ResultsColumnRenderers';
import { resultsCellRenderers } from '../resultsListing/renderers/ResultsCellRenderers';

export function Archetypes() {
    const dispatch = useDispatch();
    let listItems = useSelector((state) => state?.archetypes?.listItems);
    listItems = (listItems || []).filter((item) => {
        return item && item?.id;
    });
    const nextCursor = useSelector((state) => state?.archetypes?.nextCursor);
    const isFetching = useSelector((state) => state?.archetypes?.isFetching);
    const loadingState = useSelector((state) => state?.archetypes?.loadingState);
    const searchFilters = useSelector((state) => state?.archetypes?.filters);
    const sortBy = useSelector((state) => state?.archetypes?.sortBy);
    const sortDirection = useSelector((state) => state?.archetypes?.sortDirection);

    const handleSortChange = (sortIndex) => {
        dispatch(setArchetypesSortBy(SORT_MAP[sortIndex], searchFilters));
        dispatch(fetchArchetypesItems(null, true, SORT_MAP[sortIndex], sortDirection));
    };

    const handleSortDirectionChange = (direction) => {
        dispatch(setArchetypeSortDirection(direction));
        dispatch(fetchArchetypesItems(null, true, sortBy, direction, searchFilters));
    };

    const handleMajorArchetypeChange = (value) => {
        setArchetypeMajor(value);

        const _major = value === 'any' ? '' : value;
        const _minor = archetypeMinor === 'any' ? '' : archetypeMinor;
        dispatch(
            fetchArchetypesItems(null, true, sortBy, sortDirection, {
                archetypes: `${_major},${_minor}`,
            })
        );
    };

    const handleMinorArchetypeChange = (value) => {
        setArchetypeMinor(value);

        const _major = archetypeMajor === 'any' ? '' : archetypeMajor;
        const _minor = value === 'any' ? '' : value;
        dispatch(
            fetchArchetypesItems(null, true, sortBy, sortDirection, {
                archetypes: `${_major},${_minor}`,
            })
        );
    };

    const handleLeaderboardSelectionChange = (id) => {
        // try {
        //     if (id) {
        dispatch(setAppRoute({ route: `/details/deck/${id}` }));
        //     }
        // } catch (error) {}
    };

    const handleLoadMore = () => {
        if (!isFetching && nextCursor !== null) {
            dispatch(fetchArchetypesItems(nextCursor, false, sortBy, sortDirection));
        }
    };

    let columns = [
        { name: 'sdfsd', uid: 'authorAvatarUrl', maxWidth: 50 },
        { name: 'Author', uid: 'authors' },
        { name: '', uid: 'score', width: 90 },
    ];

    let [archetypeMajor, setArchetypeMajor] = React.useState('midrange');
    let [archetypeMinor, setArchetypeMinor] = React.useState('any');

    let archetypeMajorList = [
        { name: 'any', id: 'any' },
        { name: 'midrange', id: 'midrange' },
        { name: 'control', id: 'control' },
        { name: 'combo', id: 'combo' },
    ];

    let archetypeMinorList = [
        { name: 'any', id: 'any' },

        { name: 'midrange', id: 'midrange' },
        { name: 'COMBAT', id: 'COMBAT' },
        { name: 'EXTRA COMBATS', id: 'EXTRACOMBAT' },
        { name: 'ANTHEM', id: 'ANTHEM' },
        { name: 'TOKENS', id: 'TOKENS' },
        { name: '+1/+1 Counters', id: 'PLUSONEPLUSONECOUNTERS' },
        { name: 'BATTLE CRUISER', id: 'BATTLECRUISER' },
        { name: 'REANIMATOR', id: 'REANIMATOR' },
        { name: 'VOLTRON', id: 'VOLTRON' },
        { name: 'POISON', id: 'POISON' },
        { name: 'STOMPY', id: 'STOMPY' },
        { name: 'ENCHANTRESS', id: 'ENCHANTRESS' },
        { name: 'LANDS MATTER', id: 'LANDSMATTER' },
        { name: 'GROUP SLUG', id: 'GROUPSLUG' },
        { name: 'KINDRED', id: 'KINDRED' },

        { name: 'control', id: 'control' },
        { name: 'PILLOW FORT', id: 'PILLOWFORT' },
        { name: 'COUNTERS', id: 'COUNTERS' },
        // { name: 'SPOTREMOVAL', id: 'SPOTREMOVAL' },
        { name: 'BOARD WIPES', id: 'BOARDWIPES' },
        { name: 'MLD', id: 'MLD' },
        { name: 'THEFT', id: 'THEFT' },
        { name: 'STAX', id: 'STAX' },
        { name: 'TAXES', id: 'TAXES' },
        { name: 'GOAD', id: 'GOAD' },
        { name: 'GROUP HUG', id: 'GROUPHUG' },
        { name: 'SUPER FRIENDS', id: 'SUPERFRIENDS' },

        { name: 'combo', id: 'combo' },
        { name: 'TURNS', id: 'TURNS' },
        { name: 'ARISTOCRATS', id: 'ARISTOCRATS' },
        { name: 'WHEELS', id: 'WHEELS' },
        { name: 'MILL', id: 'MILL' },
        { name: 'STORM', id: 'STORM' },
        { name: 'SPELL SLINGER', id: 'SPELLSLINGER' },
    ];

    const helmetName = `Archetypes`;
    const helmetCanonical = `https://www.commandersalt.com/authors`;
    const helmetDescription = `The saltiest MtG deck builders on the planet!`;
    const helmetTitle = `Commandersalt.com - The saltiest MtG deck builders on the planet!`;

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />

            <Flex
                direction="row"
                maxWidth={800}
                alignSelf={'center'}
                marginTop={20}
                columnGap={50}
                marginStart={10}
                marginEnd={10}
                marginBottom={0}
                wrap
            >
                <Flex direction={'row'}>
                    <Text minWidth={50} UNSAFE_className={'synergyMenuBoxLabel'}>
                        MAJOR:
                    </Text>
                    <MenuTrigger>
                        <ActionButton
                            flexBasis={300}
                            flexGrow={1}
                            flexShrink={3}
                            UNSAFE_className={'synergyMenuBoxActionLabel'}
                        >
                            {archetypeMajor.toUpperCase()}
                        </ActionButton>
                        <Menu
                            UNSAFE_className={'synergyMenuBox'}
                            items={archetypeMajorList}
                            selectedKeys={archetypeMajor}
                            onSelectionChange={(evn) => {
                                handleMajorArchetypeChange(evn?.currentKey);
                            }}
                            disabledKeys={archetypeMinor === 'any' ? ['any'] : []}
                            selectionMode="single"
                        >
                            {(item) => <Item>{item.name.toUpperCase()}</Item>}
                        </Menu>
                    </MenuTrigger>
                </Flex>
                <Flex direction={'row'}>
                    <Text minWidth={50} UNSAFE_className={'synergyMenuBoxLabel'}>
                        MINOR:
                    </Text>
                    <MenuTrigger>
                        <ActionButton
                            flexBasis={300}
                            flexGrow={1}
                            flexShrink={3}
                            UNSAFE_className={'synergyMenuBoxActionLabel'}
                        >
                            {archetypeMinor.toUpperCase()}
                        </ActionButton>
                        <Menu
                            UNSAFE_className={'synergyMenuBox'}
                            items={archetypeMinorList}
                            selectedKeys={archetypeMinor}
                            onSelectionChange={(evn) => {
                                handleMinorArchetypeChange(evn?.currentKey);
                            }}
                            disabledKeys={archetypeMajor === 'any' ? ['any'] : []}
                            selectionMode="single"
                        >
                            {(item) => <Item>{item.name.toUpperCase()}</Item>}
                        </Menu>
                    </MenuTrigger>
                </Flex>
            </Flex>
            <ResultsListing
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSortChange={handleSortChange}
                handleSortDirectionChange={handleSortDirectionChange}
                disableNavChanges={true}
                allowDeckPreview={false}
                handleSelectionChange={handleLeaderboardSelectionChange}
                columns={resultsColumns.LEADERBOARD()}
                columnRenderer={resultsColumnRenderers.LEADERBOARD}
                listItems={listItems}
                cellRenderer={resultsCellRenderers.LEADERBOARD}
                previewIsShowing={false}
                handleLoadMore={handleLoadMore}
                loadingState={loadingState}
            />
        </Flex>
    );
}
