import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, Flex, Switch } from '@adobe/react-spectrum';

import { BaseDeckPane } from './BaseDeckPane';
import { Bar, BarChart, Legend, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { getPrettyLabel } from '../../../data/CategoryPrettyDisplayUtils';

export function PowerLevelPane() {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);
    const isFringe =
        deck?.details?.powerLevel?.ratings?.fringeCEDH === true && parseFloat(deck?.powerLevelRating) >= 8.7;
    const useCEDH = deck?.details?.powerLevel?.ratings?.inferredType === 'spike' || isFringe;

    const CATEGORY_CONSTANTS = {
        consistency: {
            color: '#ff00dc',
        },
        interaction: {
            color: '#008cff',
        },
        efficiency: {
            color: '#00a600',
        },
        tutors: {
            color: '#0446c5',
        },
        landbase: {
            color: '#00ff3c',
        },
        answers: {
            color: '#faed04',
        },
        combos: {
            color: '#ff00dc',
        },
        stax: {
            color: '#F8F8FF',
        },
        taxes: {
            color: '#b9b9b9',
        },
        ramp: {
            color: '#00a600',
        },
        draw: {
            color: '#008cff',
        },
        fastmana: {
            color: '#9d9d9d',
        },
    };

    const getBlurbs = () => {
        return {
            consistency: {
                headerLabel: 'Consistency',
                tooMany: {
                    casual: '',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike: ``,
                },
            },
            interaction: {
                headerLabel: 'Interaction',
                tooMany: {
                    casual: '',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike: ``,
                },
            },
            efficiency: {
                headerLabel: 'Efficiency',
                tooMany: {
                    casual: '',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike: ``,
                },
            },
            tutors: {
                headerLabel: 'Tutors',
                tooMany: {
                    casual: '',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike: `Tutors greatly increase consistency. ${
                        deck.details.powerLevel.ratings.spike.stax >= 7
                            ? 'You appear to have a fair amount of stax effects... do any of these punish other people for tutoring?'
                            : 'Consider adding more if that is an option.'
                    }`,
                },
            },
            // landbase: {
            //     headerLabel: 'Landbase',
            //     tooMany: {
            //         casual: 'Good landbases are always an integral component of competitive decks, and this looks a bit spicy for a casual list.',
            //         spike: '',
            //     },
            //     tooFew: {
            //         casual: '',
            //         spike: 'Color fixing is hugely important for deck consistency. Additionally, lands that ETB tapped can set you turns behind everyone else.',
            //     },
            // },
            answers: {
                headerLabel: 'Interaction',
                tooMany: {
                    casual: 'Casual players typically run very little interaction. If you are running much more than everyone else, you will most likely be archenemy.',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike:
                        deck.details.powerLevel.ratings.spike.stax >= 10
                            ? 'You have a lot of stax effects, which is great. However, your instant speed answers appear to be low. Consider increasing this if you have issues closing out games.'
                            : 'Competitive players run a high amount of interaction in order to protect their win conditions. If you are having issues closing out games, consider increasing this.',
                },
            },
            combos: {
                headerLabel: 'Combos',
                tooMany: {
                    casual: '',
                    spike: `${
                        deck.details.powerLevel.ratings.spike.answers <= 7
                            ? 'Are you running enough interaction to protect your combos?'
                            : ''
                    }`,
                },
                tooFew: {
                    casual: '',
                    spike: '',
                },
            },
            stax: {
                headerLabel: 'Stax',
                tooMany: {
                    casual: 'People looking for casual games tend to strongly dislike stax effects as it prevents them from having fun. Additionally, casual players typically run far less interaction than competitive players, so you are essentially playing these effects in to a vacuum.',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike: '',
                },
            },
            ramp: {
                headerLabel: 'Ramp',
                tooMany: {
                    casual: '',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike: '',
                },
            },
            draw: {
                headerLabel: 'Draw',
                tooMany: {
                    casual: '',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike: '',
                },
            },
            fastmana: {
                headerLabel: 'Fast mana',
                tooMany: {
                    casual: 'Having a lot of fast mana pieces gives you a board state that is often way ahead of everyone else in a casual pod. Consider lowering this if you are primarily playing this in non cEDH/high power settings.',
                    spike: '',
                },
                tooFew: {
                    casual: '',
                    spike: 'Fast mana pieces like Mana Crypt are pretty necessary for higher power games, as they increase your ability to quickly assemble a board state. Since other people will be running a lot of these, you may be at a disadvantage.',
                },
            },
        };
    };

    // hooks
    const [useCEDHPowerLevels, setUseCEDHPowerLevels] = React.useState(useCEDH);
    const type = useCEDHPowerLevels ? 'spike' : 'casual';

    let data = [];
    if (deck?.details?.powerLevel?.baseValues) {
        data = Object.keys(deck?.details?.powerLevel?.baseValues?.[type] || [])
            ?.sort((a, b) => {
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
            })
            ?.filter((category) => {
                return category !== 'answers' && category !== 'landbase';
            })
            ?.map((category) => {
                const item = deck?.details?.powerLevel?.scoring?.[category];
                let score = item?.score;
                let baseline = deck?.details?.powerLevel?.baseValues?.[type]?.[category];

                if (category === 'landbase' && !useCEDH && !useCEDHPowerLevels) {
                    baseline = baseline / 5;
                    score = score / 5;
                }

                if (category === 'synergy') {
                    baseline = baseline / 15;
                    score = score / 15;
                }

                if (category === 'winConditions') {
                    score = score > 500 ? 500 : score;
                }

                if (score < 1) {
                    score = 1;
                }

                let label = getPrettyLabel(category);

                return {
                    name: `${label?.toUpperCase()}    `,
                    score: ~~score,
                    category,
                    baseline: ~~baseline,
                    color: CATEGORY_CONSTANTS?.[category] || null,
                };
            });
    }

    const getCategories = () => {
        const list = {};

        Object.keys(deck?.details?.powerLevel?.baseValues?.[type])
            ?.filter((category) => {
                switch (category) {
                    case 'fastmana':
                    case 'taxes':
                    case 'stax':
                    case 'tutors':
                    case 'draw':
                    case 'answers':
                    case 'ramp':
                    case 'wincon':
                    case 'combo':
                        return false;
                        break;
                    default:
                        return true;
                }
            })
            ?.map((category) => {
                let item = deck?.details?.powerLevel?.scoring?.[category];
                if (category !== 'landbase' && category !== 'synergy') {
                    let subCategoryList = {};
                    let items = [];
                    let label = getPrettyLabel(category);

                    if (item?.subCategories?.length > 0) {
                        item?.subCategories.forEach((subCategory) => {
                            if (subCategory !== 'landbase') {
                                let subLabel = getPrettyLabel(subCategory);

                                if (subCategory === 'combos') {
                                    subCategoryList[subCategory] = {
                                        label: `${subLabel?.toUpperCase()}`,
                                        id: subCategory,
                                        count: Object.keys(deck?.details?.powerLevel?.scoring?.[subCategory]?.list)
                                            ?.length,
                                        list: Object.keys(deck?.details?.powerLevel?.scoring?.[subCategory]?.list)?.map(
                                            (_key) => {
                                                const _entry =
                                                    deck?.details?.powerLevel?.scoring?.[subCategory]?.list[_key];
                                                return {
                                                    ..._entry,
                                                    type: 'combo',
                                                    cards: (_entry?.cards || []).map((__card) => {
                                                        return cards?.[__card];
                                                    }),
                                                };
                                            }
                                        ),
                                        parentCategory: category,
                                    };

                                    items = items.concat(
                                        Object.keys(deck?.details?.powerLevel?.scoring?.[subCategory]?.list)?.map(
                                            (_key) => {
                                                const _entry =
                                                    deck?.details?.powerLevel?.scoring?.[subCategory]?.list[_key];
                                                return {
                                                    ..._entry,
                                                    type: 'combo',
                                                    cards: (_entry?.cards || []).map((__card) => {
                                                        return cards?.[__card];
                                                    }),
                                                };
                                            }
                                        ) || []
                                    );
                                } else {
                                    subCategoryList[subCategory] = {
                                        label: `${subLabel?.toUpperCase()}`,
                                        id: subCategory,
                                        count: Object.keys(deck?.details?.powerLevel?.scoring?.[subCategory]?.list)
                                            ?.length,
                                        list: Object.keys(deck?.details?.powerLevel?.scoring?.[subCategory]?.list)?.map(
                                            (_key) => {
                                                const _card =
                                                    deck?.details?.powerLevel?.scoring?.[subCategory]?.list[_key];
                                                return { ..._card, ...cards?.[_card?.id] };
                                            }
                                        ),
                                        parentCategory: category,
                                    };

                                    items = items.concat(
                                        Object.keys(deck?.details?.powerLevel?.scoring?.[subCategory]?.list)?.map(
                                            (_key) => {
                                                const _card =
                                                    deck?.details?.powerLevel?.scoring?.[subCategory]?.list[_key];
                                                return {
                                                    ..._card,
                                                    ...cards?.[_card?.id],
                                                    id: `${category}_${_card?.id}`,
                                                };
                                            }
                                        ) || []
                                    );
                                }
                            }
                        });

                        const itemsHash = {};
                        items.map((item) => {
                            if (!itemsHash[item.id]) {
                                itemsHash[item.id] = item;
                            } else {
                                itemsHash[item.id].score = (
                                    parseFloat(itemsHash[item.id].score) + parseFloat(item.score)
                                ).toFixed(1);
                            }
                        });

                        items = Object.keys(itemsHash)?.map((key) => {
                            return itemsHash[key];
                        });

                        // console.log(JSON.stringify(itemsHash, null, 4));
                    } else {
                        items = Object.keys(deck?.details?.powerLevel?.scoring?.[category]?.list || []).map((key) => {
                            const item = deck?.details?.powerLevel?.scoring?.[category]?.list[key];
                            return {
                                ...item,
                                ...cards?.[item?.id],
                            };
                        });
                    }

                    if (items?.length > 1) {
                        items = items.sort((a, b) => {
                            if (b?.score > a?.score) {
                                return 1;
                            }

                            return -1;
                        });
                    }

                    list[category] = {
                        label: `${label?.toUpperCase()}`,
                        id: category,
                        count: items?.length || 0,
                        list: items,
                        color: CATEGORY_CONSTANTS?.[category]?.color || `#FFFFFF`,
                        subCategories: subCategoryList,
                    };
                }
            });

        Object.keys(list).forEach((key) => {
            if (list?.[key]?.list?.length < 1) {
                delete list?.[key];
            } else {
                Object.keys(list?.[key]?.subCategories).forEach((subKey) => {
                    if (list?.[key]?.subCategories?.[subKey]?.list?.length < 1) {
                        delete list?.[key]?.subCategories?.[subKey];
                    }
                });
            }
        });

        return list;
    };

    const handleBarClick = (data, index) => {};

    const getDiscussionPoints = () => {
        const list = [];

        Object.keys(deck?.details?.powerLevel?.baseValues?.casual)?.map((category) => {
            const actual = deck.details.powerLevel.scoring[category].score;
            const baseline = deck.details.powerLevel.baseValues.casual[category];

            let blurb = null;
            let direction = null;
            const blurbMap = getBlurbs()?.[category];

            if (actual / baseline > 2) {
                direction = 'tooMany';
            }

            if (actual / baseline <= 0.6) {
                direction = 'tooFew';
            }

            if (direction) {
                const soapbox = blurbMap?.[direction]?.casual;

                if (soapbox && soapbox != '') {
                    blurb = soapbox;
                }
            }

            if (blurb) {
                list.push({
                    category,
                    blurb,
                    header: blurbMap.headerLabel,
                });
            }
        });

        return list;
    };

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    window.addEventListener(
        'resize',
        function (event) {
            // console.log(`sdfksdf`);
            // forceUpdate();
        },
        true
    );

    const temp = getCategories();

    return (
        <BaseDeckPane
            title={'Power Levels'}
            fieldName="name"
            content={<div />}
            showScore={true}
            categories={getCategories()}
        >
            <Flex direction="column">
                <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                    <p />
                    How would other players view your deck?
                </span>
                <Divider width="100%" marginTop="10px" size={'S'} />
                <Flex direction="row" justifyContent="center">
                    <Flex
                        direction="column"
                        justifyContent="space-around"
                        marginEnd="10px"
                        width={'100%'}
                        marginStart={10}
                    >
                        {/*<Flex direction={'column'} justifyContent={'space-around'} width={'100%'}>*/}
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                maxWidth: '700px',
                                paddingBottom: '250px',
                                height: 200,
                                alignSelf: 'center',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    top: 0,
                                }}
                            >
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart layout="vertical" data={data} barGap={1} tick>
                                        <XAxis type="number" />
                                        <YAxis dataKey="name" type="category" width={100} tickLine={false} />
                                        <Legend />
                                        <Bar dataKey="score" fill="#8884d8" barSize={20} onClick={handleBarClick} />
                                        <Bar dataKey="baseline" fill="#82ca9d" barSize={10} onClick={handleBarClick} />
                                    </BarChart>
                                </ResponsiveContainer>
                                <Switch isSelected={useCEDHPowerLevels} onChange={setUseCEDHPowerLevels}>
                                    Use cEDH baseline scores
                                </Switch>
                            </div>
                        </div>
                        {/*</Flex>*/}

                        <div>
                            {getDiscussionPoints()?.length > 0 && type === 'casual' && (
                                <Divider width="100%" marginTop="5px" size={'S'} />
                            )}
                            <Flex
                                direction="column"
                                justifyContent="center"
                                // width="clamp(0px, calc(100vw - 60px), 760px)"
                            >
                                <p />
                                {getDiscussionPoints()?.length > 0 && type === 'casual' && (
                                    <div className="panelText" style={{ marginTop: '-20px' }}>
                                        <p>
                                            <span style={{ color: '#ffdf61', fontSize: '15px', fontWeight: 'bolder' }}>
                                                Possible topics for this deck when discussing power levels with a{' '}
                                                <u>{type === 'casual' ? 'casual' : 'competitive'}</u> pod:
                                            </span>
                                        </p>
                                        {getDiscussionPoints()?.map((item) => (
                                            <Flex
                                                direction="column"
                                                marginBottom={'20px'}
                                                key={`blurbItem_${type}_${item?.header}`}
                                            >
                                                <b>{item?.header}:</b>
                                                {item?.blurb}
                                            </Flex>
                                        ))}
                                    </div>
                                )}
                            </Flex>
                        </div>

                        {/*<span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>*/}
                        {/*    <p />*/}
                        {/*    Note: The saltiness of a deck has no bearing on it's power level score.*/}
                        {/*    <p />*/}
                        {/*</span>*/}
                    </Flex>
                </Flex>
            </Flex>
        </BaseDeckPane>
    );
}
