export const resultsColumns = {
    UPGRADES: () => {
        return [{ name: 'Cards', uid: 'name' }];
    },
    LEADERBOARD: () => {
        const max = window.innerWidth - 180;

        let columns = [
            { name: 'user', uid: 'authorAvatarUrl', maxWidth: 65, minWidth: 65, width: 65 },
            { name: 'Title and Commander(s)', uid: 'commanders', maxWidth: max, minWidth: 200, width: max },
            { name: '', uid: 'saltRating', maxWidth: 90, minWidth: 20, width: 90 },
        ];

        return columns;
    },
};
