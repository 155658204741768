import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, Sector, ResponsiveContainer } from 'recharts';
import React, { useState } from 'react';
import { Flex } from '@adobe/react-spectrum';
import { BaseDeckPane } from './BaseDeckPane';
import isMobile from 'ismobilejs';
import { getPrettyLabel } from '../../../data/CategoryPrettyDisplayUtils';

export function CategoriesPane(props) {
    const cards = useSelector((state) => state.deck.cards);

    const getCategories = () => {
        const list = {};

        Object.keys(cards || {})?.map((id) => {
            const card = cards[id];
            Object.keys(card?.categories?.stats || {}).forEach((cat) => {
                switch (cat) {
                    case 'slow':
                        break;
                    default:
                        if (!list.hasOwnProperty(cat)) {
                            list[cat] = {
                                label: getPrettyLabel(cat),
                                id: cat,
                                count: 0,
                                list: [],
                                color: `#FFFFFF`,
                            };
                        }

                        list[cat].list.push({ ...card });
                        list[cat].count += 1;
                }
            });
        });

        return list;
    };

    const debug = getCategories();

    return (
        <BaseDeckPane
            title={'Categories'}
            fieldName="name"
            content={<div />}
            showScore={false}
            categories={getCategories()}
            skipSubCategories={true}
        ></BaseDeckPane>
    );
}
