import React from 'react';
import User from '@spectrum-icons/workflow/User';

export const resultsColumnRenderers = {
    UPGRADES: (item) => {
        // let content;
        //
        // switch (item.uid) {
        //     case 'saltRating':
        //         content = <div />;
        //         break;
        //     case 'authorAvatarUrl':
        //         content = <User size="S" />;
        //         break;
        //     default:
        //         content = item.name;
        // }

        return <div>{item.name}</div>;
    },
    LEADERBOARD: (item) => {
        let content;

        switch (item.uid) {
            case 'saltRating':
                content = <div />;
                break;
            case 'authorAvatarUrl':
                content = <User size="S" />;
                break;
            default:
                content = item.name;
        }

        return <div>{content}</div>;
    },
};
