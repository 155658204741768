import React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            ...props,
        };
    }

    static getDerivedStateFromError(error) {
        return { error: error };
    }

    componentDidCatch(error, info) {
        try {
            // logEvent(getAnalytics(), 'ERROR_BOUNDARY', { route: this.state?.route, error: error, info: info });
        } catch (e) {
            // console.log(`unable to log error!`, e);
        }

        console.log(info, error);
    }

    render() {
        if (this.state.error) {
            return (
                <div
                    style={{
                        fontSize: '2em',
                        fontWeight: 'bolder',
                        textAlign: 'center',
                        marginTop: '50px',
                        color: '#FFF',
                    }}
                >
                    <img
                        style={{ maxWidth: '90vw' }}
                        src={
                            'https://cards.scryfall.io/art_crop/front/5/2/52558748-6893-4c72-a9e2-e87d31796b59.jpg?1559959349'
                        }
                    />
                    <span style={{ maxWidth: '90vw' }}>
                        <p>Whoops! Something broke here.</p>
                    </span>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
