import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Text,
    Flex,
    Heading,
    Header,
    Content,
    ButtonGroup,
    Button,
    ActionButton,
    View,
    Item,
    Tabs,
    TabList,
    TabPanels,
    Divider,
    Dialog,
    DialogTrigger,
    DialogContainer,
    ContextualHelp,
} from '@adobe/react-spectrum';
import styles from './Deck.css';

import { REPORT_CARD_GRADE_CODES, getGrades } from '../../data/ScoreDisplayUtils';
import Servers from '@spectrum-icons/workflow/Servers';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import Refresh from '@spectrum-icons/workflow/Refresh';
import FlashOn from '@spectrum-icons/workflow/FlashOn';
import Beaker from '@spectrum-icons/workflow/Beaker';
import Bookmark from '@spectrum-icons/workflow/Bookmark';
import DataMapping from '@spectrum-icons/workflow/DataMapping';
import Gauge4 from '@spectrum-icons/workflow/Gauge4';
import GraphBarVertical from '@spectrum-icons/workflow/GraphBarVertical';
import ColorPalette from '@spectrum-icons/workflow/ColorPalette';
import HotFixes from '@spectrum-icons/workflow/HotFixes';
import { ImportStatusBar } from '../importStatusBar/ImportStatusBar';
import { doRefresh } from '../../data/redux/slices/importSlice';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import { ProgressCircle } from '@adobe/react-spectrum';
import User from '@spectrum-icons/workflow/User';
import { Helmet } from 'react-helmet';
import { CombosPane } from './panes/CombosPane';
import { PowerLevelPane } from './panes/PowerLevelPane';
import { DeckListPane } from './panes/DeckListPane';
import { SaltPane } from './panes/SaltPane';
import { SynergyPane } from './panes/SynergyPane';
import isMobile from 'ismobilejs';
import { ManabasePane } from './panes/ManabasePane';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { SubmitForm } from '../submitform/SubmitForm';
import { UpgradelizerPane } from './panes/UpgradelizerPane';
import { DownloadIcon } from 'react-modal-image/lib/icons';
import { getDomainPrefix } from '../../data/DynamoConnector';
import { ArchetypesPane } from './panes/ArchetypesPane';
import { CategoriesPane } from './panes/CategoriesPane';
import { PrintDialog } from '../widgets/printDialog/PrintDialog';

export function Deck(props) {
    const deck = useSelector((state) => state.deck.deck);
    const isRefreshing = useSelector((state) => state.import.isRefreshing);
    const isImporting = useSelector((state) => state.import.isImporting);
    const isFailedFetch = useSelector((state) => state.deck.isFailedFetch);

    // hooks
    const dispatch = useDispatch();
    const mobile = isMobile(window.navigator).phone;

    const handleAuthorLinkPress = () => {
        logEvent(getAnalytics(), 'preview_open_author', { url: deck?.author?.url });
        window.open(deck?.author?.url, `_blank`);
    };

    const handleDeckLinkPress = () => {
        const url = deck?.deckUri?.replace(/\/\?cb\=.*/, '') || '';
        console.log(`URL => ${url}`);
        logEvent(getAnalytics(), 'preview_open_deck', { url });
        window.open(url, `_blank`);
    };

    const grades = getGrades();
    const saltPassing =
        grades.salt.grade === REPORT_CARD_GRADE_CODES.A_PLUS ||
        grades.salt.grade === REPORT_CARD_GRADE_CODES.A ||
        grades.salt.grade === REPORT_CARD_GRADE_CODES.A_MINUS ||
        grades.salt.grade === REPORT_CARD_GRADE_CODES.B_PLUS ||
        grades.salt.grade === REPORT_CARD_GRADE_CODES.B;

    const getRemarks = () => {
        let saltQuote;
        let threatQuote;
        let comboQuote;

        switch (grades.salt.grade) {
            case REPORT_CARD_GRADE_CODES.A_PLUS:
                saltQuote = 'Aggressively salted';
                break;
            case REPORT_CARD_GRADE_CODES.A:
            case REPORT_CARD_GRADE_CODES.A_MINUS:
            case REPORT_CARD_GRADE_CODES.B_PLUS:
                saltQuote = 'Perfectly seasoned';
                break;
            case REPORT_CARD_GRADE_CODES.B:
                saltQuote = 'Nicely seasoned';
                break;
            case REPORT_CARD_GRADE_CODES.B_MINUS:
            case REPORT_CARD_GRADE_CODES.C_PLUS:
            case REPORT_CARD_GRADE_CODES.C:
            case REPORT_CARD_GRADE_CODES.C_MINUS:
                saltQuote = 'A bit underseasoned';
                break;
            case REPORT_CARD_GRADE_CODES.D:
            case REPORT_CARD_GRADE_CODES.D_PLUS:
                saltQuote = 'Rather bland';
                break;
            default:
                saltQuote = 'Completely devoid of salt';
                break;
        }

        switch (grades.threat.grade) {
            case REPORT_CARD_GRADE_CODES.A_PLUS:
                threatQuote = ` ${saltPassing ? ' and' : ' but otherwise'} expertly prepared`;
                break;
            case REPORT_CARD_GRADE_CODES.A:
            case REPORT_CARD_GRADE_CODES.A_MINUS:
            case REPORT_CARD_GRADE_CODES.B_PLUS:
            case REPORT_CARD_GRADE_CODES.B:
            case REPORT_CARD_GRADE_CODES.B_MINUS:
                threatQuote = `  ${saltPassing ? ' and' : ' but'}  well prepared`;
                break;
            case REPORT_CARD_GRADE_CODES.C_PLUS:
            case REPORT_CARD_GRADE_CODES.C:
                threatQuote = ` ${saltPassing ? ' yet' : ' and'} a touch undercooked`;
                break;
            default:
                threatQuote = ` ${saltPassing ? ' yet somehow' : ' and'} sloppily plated`;
                break;
        }

        if (deck?.powerLevelRating >= 9) {
            comboQuote = `...a competitive dish.`;
        } else if (deck?.powerLevelRating >= 7) {
            comboQuote = `... a delightful repast.`;
        } else if (deck?.powerLevelRating >= 5) {
            comboQuote = '... excellent for dinner with friends';
        } else {
            comboQuote = '... perfect for the kitchen table';
        }

        if (deck?.details?.manabase?.details?.lowLandCountFloorExceeded) {
            return `"Highly delicious, yet has the nutritional value of styrofoam."`;
        }

        if (deck?.powerLevelRating > 2) {
            return `"${saltQuote}${threatQuote}${comboQuote}"`;
        } else {
            return `"I ordered cupcakes... and I received a salad."`;
        }
    };

    const avatarUrl = deck?.author?.profileImageUrl;
    const title = deck?.title;
    const author = deck?.author?.username;
    let commanderList = deck?.commanders;
    if (!Array.isArray(commanderList)) {
        commanderList = [deck?.commanders];
    }
    let commanders = ``;
    commanderList.forEach((commander) => {
        if (commanders === ``) {
            commanders = commander;
        } else {
            commanders = `${commanders}\n${commander}`;
        }
    });

    const authorUrlButtonDisabled = !deck?.author?.url;

    const generatePowerLevelComponent = () => {
        let score = 0;
        if (deck?.powerLevelDisplayValue) {
            score = deck?.powerLevelDisplayValue;
        }

        const barHeaderList = [
            {
                style: { marginLeft: '-5px', width: '30%' },
                label: '┌───────',
                id: '0',
            },
            {
                style: { textAlign: 'center', whiteSpace: 'nowrap', width: '100%' },
                label: 'PRACTICAL POWER LEVEL',
                id: '1',
            },
            {
                style: { marginRight: '-.4em', textAlign: 'right', width: '30%' },
                label: '───────┐',
                id: '2',
            },
        ];

        const baseWidth = window.innerWidth - 30 < 500 ? window.innerWidth - 30 : 500;
        const footerSpacerWidth = (baseWidth / 10) * 8;
        const footerTextWidth = baseWidth - footerSpacerWidth;

        const barList = [];
        const barFooterList = [
            { style: { width: `${footerSpacerWidth}px` }, label: '', id: '0' },
            { style: { margin: '0px', width: '5px' }, label: '└', id: '1' },
            {
                style: { textAlign: 'center', width: `${footerTextWidth}px` },
                label: 'CEDH',
                id: '2',
            },
            { style: { margin: '0px', width: '5px' }, label: '┘', id: '3' },
        ];

        /**
         * const colorValue = Math.ceil(100 - percentage);
         *
         *     // const colorValue = Math.ceil(percentage);
         *     return `hsl(${colorValue} 100% 50%)`;
         */
        for (let i = 1; i <= 10; i++) {
            const colorValue = 100 - i * 10;
            const showBackground = i <= score;
            const showText = i >= score;
            let opacity = 80;
            if (i === score) opacity = 100;
            if (i > score) opacity = 20;

            barList.push({
                score: `${showText ? i.toString() : ''}`,
                id: i,
                className: '', //i === score ? 'perceivedPowerLevelScore' : '',
                opacity: `${i > score ? 10 : i === score ? 100 : 80}%`,
                backgroundColor: `hsl(${colorValue} 100% 50%)`,
            });
        }

        return (
            <Flex
                direction="row"
                justifyContent="center"
                marginTop={20}
                // UNSAFE_style={{ margin: '0 auto' }}
            >
                <Flex direction="column">
                    <Flex direction="row">
                        {barHeaderList.map((item) => {
                            return (
                                <div
                                    key={`gridCellPowerLevelHeader_${item.id}`}
                                    style={{ fontWeight: 'bold', fontSize: '.7rem', ...item.style }}
                                >
                                    {item.label}
                                </div>
                            );
                        })}
                    </Flex>
                    <Flex direction="row">
                        {barList.map((item) => {
                            return (
                                <div
                                    key={`gridCellPowerLevel_${item.id}`}
                                    className={item.className}
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        textAlign: 'center',
                                        fontWeight: 'bolder',
                                        backgroundColor: item.backgroundColor,
                                        fontColor: '#FF0000',
                                        fontSize: '1.5rem',
                                        textShadow: '2px 2px 3px #000000',
                                        opacity: item.opacity,
                                    }}
                                >
                                    {item.score}
                                </div>
                            );
                        })}
                    </Flex>
                    <Flex direction="row" maxWidth="size-6000">
                        {barFooterList.map((item) => {
                            return (
                                <div
                                    key={`gridCellPowerLevelFooter_${item.id}`}
                                    style={{ fontWeight: 'bold', fontSize: '.7rem', ...item.style }}
                                >
                                    {item.label}
                                </div>
                            );
                        })}
                    </Flex>
                    <div style={{ textAlign: 'center', fontSize: '10px' }}>
                        <Text>Absolute (uncapped) power level score: {deck?.powerLevelRating}</Text>
                    </div>
                    {/*{deck?.details?.manabase?.details?.lowLandCountFloorExceeded && (*/}
                    {/*    <div style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}>*/}
                    {/*        Note: The Power Level for this deck has been capped due to having an apparently unplayable*/}
                    {/*        landbase.*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </Flex>
            </Flex>
        );
    };

    let header = document.getElementById('headerContainer');
    let preview = document.getElementById('previewDialog');

    const updateContentHeight = () => {
        const headerHeight = header.clientHeight;
        const previewHeight = preview.clientHeight;

        const previewScrollContainer = document.getElementById('previewScrollContainer');
        previewScrollContainer.style.setProperty(`max-height`, `${previewHeight - headerHeight - 70}px`);
    };

    const setAwaitTimer = () => {
        header = document.getElementById('headerContainer');
        preview = document.getElementById('previewDialog');

        if (!header || !preview) {
            setTimeout(() => {
                setAwaitTimer();
            }, 500);
        } else {
            updateContentHeight();
        }
    };

    setAwaitTimer();

    let [tab, setTab] = React.useState('powerLevel');

    const handleTabSelectionChange = (evn) => {
        if (tab !== evn) {
            setTab(evn);

            logEvent(getAnalytics(), `preview_setTab_${evn.toUpperCase()}`);
        }

        const tabList = document.getElementById('tabPanel');
        const tabListTop = tabList.getBoundingClientRect().top;

        if (mobile) {
            window.scrollBy({ top: tabListTop, left: 0, behavior: 'smooth' });
        }
    };

    const handleCommanderLinkClick = () => {
        // if (!props?.disableNavChanges) {
        dispatch(setAppRoute({ route: `/commanders/id/${encodeURIComponent(deck?.commanderIndexId)}` }));
        // }
    };

    const handleAuthorClick = () => {
        // if (!props?.disableNavChanges) {
        dispatch(setAppRoute({ route: `/authors/id/${encodeURIComponent(deck?.authorIndexId)}` }));
        // }
    };

    const handleChangeLogClick = () => {
        logEvent(getAnalytics(), 'preview_changelog', { url: deck?.deckUri });
        // dispatch(setAppRoute({ route: `/changelog` }));
        // TODO: what the hell is happening here
        window.location = 'https://www.commandersalt.com/changelog';
    };

    const handleAlgorithmClick = () => {
        logEvent(getAnalytics(), 'preview_algorithm', { url: deck?.deckUri });
        // dispatch(setAppRoute({ route: `/changelog` }));
        // TODO: what the hell is happening here
        dispatch(setAppRoute({ route: `/algorithm` }));
    };

    const allowManabase = !deck?.staleDeckData && deck?.details?.hasOwnProperty('manabase');

    if (deck && deck?.id) {
        if (window.location.pathname.includes(`/details/deck/`) && !window.location.pathname.includes(deck?.id)) {
            window.history.replaceState({}, null, `/details/deck/${deck.id}`);
        }
    }

    const ingestDate = new Date(
        deck?.ingestDate?.ingestDate?.ingestDate || deck?.ingestDate?.ingestDate || deck?.ingestDate
    );
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const timeStamp = `${months[ingestDate.getMonth()]} ${ingestDate.getDate()}, ${ingestDate.getFullYear()}`;
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    const commentIndex = getRandomInt(deck?.manuel?.listOfResponses?.length);
    const comment = deck === null ? '' : deck?.manuel?.listOfResponses?.[commentIndex];

    const helmetName = `${deck?.title}`;
    const helmetCanonical = `https://www.commandersalt.com/details/deck/${deck?.id}`;
    const helmetDescription = `Commandersalt.com analysis - ${deck?.title}`;
    const helmetTitle = `${deck?.title}`;

    const displayProgressBar = isImporting || isRefreshing;
    const progressDialogHeader = isImporting ? 'Importing deck...' : 'Refreshing deck...';

    const getPrettyArchetypeLabel = (label) => {
        label = label.toLowerCase();
        // label = label === 'tutors' ? 'tutor' : label;
        // label = label === 'landbase' ? 'manabase' : label;
        // label = label === 'winConditions' ? 'win cons' : label;
        // label = label === 'fastmana' ? 'fast mana' : label;
        // label = label === 'combos' ? 'combo' : label;
        // label = label === 'answers' ? 'interaction' : label;
        // label = label === 'wincon' ? 'other' : label;

        label = label === 'spotremoval' ? 'spot removal' : label;
        label = label === 'cantrip' ? 'draw' : label;
        label = label === 'counters' ? 'counterspells' : label;
        label = label === 'plusoneplusonecounters' ? '+1/+1 COUNTERS' : label;
        label = label === 'extracombat' ? 'EXTRA COMBAT' : label;

        // console.log(label)
        label = label.toUpperCase();

        return label;
    };

    return (
        <Flex direction={'column'}>
            {deck && (
                <Helmet>
                    <title>{helmetName}</title>
                    <meta name="creator" content="Ben Rimbey" />
                    <link rel="canonical" href={helmetCanonical} />
                    <meta name="description" content={helmetDescription} />
                    <meta name="twitter:title" content={helmetTitle} />
                    <meta property="og:site_name" content="Commandersalt.com" />
                    <meta property="og:title" content={helmetTitle} />
                    <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                    <meta property="og:description" content={helmetDescription} />
                </Helmet>
            )}
            {displayProgressBar && (
                <DialogContainer type={'modal'}>
                    <Dialog width={'calc(100vw - 40px)'} maxWidth={800}>
                        <Heading>{progressDialogHeader}</Heading>
                        {/*<Header>Connection status: Connected</Header>*/}
                        <Divider />
                        <Content>
                            {/*<div style={{ maxWidth: 800, width: '100%' }}>*/}
                            {/*<Text>Start speed test?</Text>*/}
                            <Flex direction={'row'} UNSAFE_style={{ paddingTop: 20 }}>
                                {<ImportStatusBar />}
                            </Flex>
                        </Content>
                        {/*<ButtonGroup>*/}
                        {/*    <Button variant="secondary" onPress={close}>*/}
                        {/*        Cancel*/}
                        {/*    </Button>*/}
                        {/*</ButtonGroup>*/}
                    </Dialog>
                </DialogContainer>
            )}

            <BreadCrumbs
                content={
                    <Flex direction="row" gap="size-100" justifyContent="right">
                        <View
                            borderWidth="thin"
                            borderColor="light"
                            borderRadius="medium"
                            padding="size-50"
                            backgroundColor="gray-75"
                            minHeight="0px"
                        >
                            <Flex direction="row" gap="size-100">
                                {!mobile && (
                                    <div style={{ cursor: 'pointer' }} onClick={handleDeckLinkPress}>
                                        <Text
                                            UNSAFE_className="reportCardSectionTitles"
                                            marginStart={10}
                                            top={7}
                                            position={'relative'}
                                        >
                                            {(/[a-zA-Z]*(?=\.[a-zA-Z]*$)/.exec(deck?.source)?.[0] || '').toUpperCase()}
                                        </Text>
                                    </div>
                                )}
                                <ActionButton
                                    onPress={handleDeckLinkPress}
                                    isDisabled={!deck}
                                    aria-label="View deck"
                                    isQuiet="true"
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                >
                                    <LinkOut />
                                </ActionButton>
                                <ActionButton
                                    aria-label="View author profile"
                                    isDisabled={authorUrlButtonDisabled || !deck}
                                    onPress={handleAuthorLinkPress}
                                    isQuiet="true"
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                >
                                    <User />
                                </ActionButton>
                            </Flex>
                        </View>
                        <View
                            borderWidth="thin"
                            borderColor="light"
                            borderRadius="medium"
                            padding="size-50"
                            backgroundColor="gray-75"
                            minHeight="0px"
                        >
                            <Flex direction="row" gap="size-100">
                                {!mobile && (
                                    <div
                                        style={deck ? { cursor: 'pointer' } : {}}
                                        onClick={() => {
                                            if (!isImporting && !isRefreshing && deck) {
                                                logEvent(getAnalytics(), 'preview_refresh', {
                                                    url: deck?.deckUri,
                                                });
                                                dispatch(doRefresh(deck?.deckUri || deck?.url));
                                            }
                                        }}
                                    >
                                        <Text
                                            UNSAFE_className="reportCardSectionTitles"
                                            marginStart={10}
                                            top={7}
                                            position={'relative'}
                                        >
                                            REFRESH
                                        </Text>
                                    </div>
                                )}
                                <ActionButton
                                    type="reset"
                                    alignSelf="flex-end"
                                    isDisabled={isImporting || isRefreshing || !deck}
                                    isQuiet="true"
                                    aria-label="Refresh deck"
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                    onPress={() => {
                                        logEvent(getAnalytics(), 'preview_refresh', {
                                            url: deck?.deckUri,
                                        });
                                        dispatch(doRefresh(deck?.deckUri || deck?.url));
                                    }}
                                >
                                    <Refresh />
                                </ActionButton>
                            </Flex>
                        </View>
                        <View
                            borderWidth="thin"
                            borderColor="light"
                            borderRadius="medium"
                            padding="size-50"
                            backgroundColor="red-400"
                            minHeight="0px"
                        >
                            <Flex direction="row" gap="size-100">
                                <DialogTrigger type={'modal'}>
                                    <ActionButton
                                        type="reset"
                                        alignSelf="flex-end"
                                        isDisabled={isImporting || isRefreshing}
                                        isQuiet="true"
                                        UNSAFE_style={{ cursor: 'pointer' }}
                                        aria-label="Import new deck"
                                        // onPress={handleAddNewDeckPress}
                                    >
                                        <AddCircle />
                                    </ActionButton>
                                    {(close) => (
                                        <Dialog width={'calc(100vw - 40px)'} maxWidth={800}>
                                            <Heading>Import Deck</Heading>
                                            {/*<Header>Connection status: Connected</Header>*/}
                                            <Divider />
                                            <Content>
                                                {/*<div style={{ maxWidth: 800, width: '100%' }}>*/}
                                                {/*<Text>Start speed test?</Text>*/}
                                                <Flex direction={'row'}>
                                                    {
                                                        // isImporting ? <ImportStatusBar /> : <SubmitForm /> // initialListUrl={param} />
                                                        <SubmitForm onStartCallback={close} />
                                                    }
                                                </Flex>
                                            </Content>
                                            <ButtonGroup>
                                                <Button variant="secondary" onPress={close}>
                                                    Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Dialog>
                                    )}
                                </DialogTrigger>
                            </Flex>
                        </View>
                    </Flex>
                }
            />

            {/*// <Flex UNSAFE_className="modal-main display-block" id="previewDialog">*/}
            {/*//     <Helmet>*/}
            {/*//         <meta*/}
            {/*//             name="description"*/}
            {/*//             content={`Analysis for ${deck?.title}, a deck by ${author} using ${deck?.commanders?.toString()}`}*/}
            {/*//         />*/}
            {/*//     </Helmet>*/}
            {(deck && (
                <Flex direction="column" margin={0}>
                    <>
                        <Flex
                            direction="row"
                            justifyContent={'space-evenly'}
                            columnGap={50}
                            marginTop={10}
                            marginStart={10}
                            marginEnd={10}
                            marginBottom={0}
                            wrap
                        >
                            <div
                                style={{
                                    display: 'initial',
                                    flex: '1 1 300px',
                                    // marginBottom: '10px',
                                    width: 300,
                                    minWidth: 300,
                                    maxWidth: 700,
                                }}
                            >
                                <View>
                                    <Flex
                                        direction="row"
                                        // UNSAFE_className={'deckHeaderLeft'}
                                        width={'100%'}
                                        // marginBottom={30}
                                        columnGap={30}
                                        justifyContent={'space-between'}
                                    >
                                        <div
                                            style={{
                                                display: 'initial',
                                                flex: '1 1 50px',
                                                // marginBottom: '10px',
                                                width: 50,
                                                minWidth: 50,
                                                maxWidth: 500,
                                            }}
                                        >
                                            <div onClick={handleAuthorClick} className="previewDeckCommandersText">
                                                <Flex direction="column" rowGap={10}>
                                                    <img
                                                        className="previewUserAvatarThumb"
                                                        src={avatarUrl || `/resources/blank-user-avatar.png`}
                                                        alt="avatar"
                                                    />
                                                    <Text UNSAFE_className="previewDeckCommandersText">{author}</Text>
                                                </Flex>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'initial',
                                                flex: '2 1 100px',
                                                // marginBottom: '10px',
                                                width: 300,
                                                minWidth: 200,
                                                maxWidth: 400,
                                            }}
                                        >
                                            <Flex direction="column">
                                                <Text UNSAFE_className="previewDeckTitleLabel">{title}</Text>
                                                <Text UNSAFE_className="previewDeckCommandersLabel">Commander(s):</Text>
                                                <div style={{ height: '10px' }} />
                                                <span onClick={handleCommanderLinkClick}>
                                                    <Text UNSAFE_className="previewDeckCommandersText">
                                                        {commanders}
                                                    </Text>
                                                </span>
                                            </Flex>
                                        </div>
                                    </Flex>
                                </View>
                            </div>
                            <div
                                style={{
                                    display: 'initial',
                                    flex: '2 2 300px',
                                    // marginBottom: '10px',
                                    width: 300,
                                    minWidth: 300,
                                }}
                            >
                                <View>
                                    <Flex direction="column" rowGap="10px" margin={0}>
                                        <Text UNSAFE_className="SaltScoreHeader" alignSelf="center">
                                            OUR CHEF'S REMARKS
                                        </Text>
                                        <Text UNSAFE_className="chefsRemarksText" alignSelf="center">
                                            {getRemarks()}
                                        </Text>
                                        <div
                                            onClick={() => handleTabSelectionChange('powerLevel')}
                                            className={'reportCardItemWrapper'}
                                        >
                                            {generatePowerLevelComponent()}
                                        </div>
                                        <Flex
                                            alignContent={'center'}
                                            justifyContent={'center'}
                                            columnGap={5}
                                            direction={'row'}
                                            marginTop={-10}
                                            UNSAFE_className={'previewPowerLevelChangedLabel'}
                                            wrap
                                        >
                                            <span style={{ fontSize: '12px' }} onClick={handleAlgorithmClick}>
                                                How is this score determined?
                                            </span>
                                        </Flex>
                                    </Flex>
                                </View>
                            </div>
                        </Flex>
                        <Flex
                            alignContent={'center'}
                            justifyContent={'center'}
                            columnGap={5}
                            direction={'row'}
                            UNSAFE_className={'previewPowerLevelChangedLabel'}
                            wrap
                        >
                            <span>Has your score changed since your last visit?</span>
                            <span onClick={handleChangeLogClick}>
                                The power level rules engine may have been updated! (Click here)
                            </span>
                        </Flex>
                        <PrintDialog deckId={deck.id} pageType={'preview'} name={deck.name} />
                        <Flex
                            direction="row"
                            justifyContent={'space-between'}
                            columnGap={20}
                            marginTop={10}
                            marginStart={10}
                            marginEnd={10}
                            marginBottom={0}
                            wrap
                        >
                            {/*{isRefreshing ? (*/}
                            {/*    <div style={{ marginTop: '5px' }}>*/}
                            {/*        <ImportStatusBar paddingTop="10px" />*/}
                            {/*    </div>*/}
                            {/*) : (*/}

                            <div
                                style={{
                                    display: 'initial',
                                    flex: '3 1 350px',
                                    // marginBottom: '10px',
                                    width: 350,
                                    minWidth: 350,
                                    maxWidth: 500,
                                }}
                            >
                                <View
                                    flex={1}
                                    borderWidth="thin"
                                    borderColor="light"
                                    borderRadius="medium"
                                    padding="size-200"
                                    backgroundColor="gray-75"
                                    minHeight="0px"
                                    UNSAFE_className={'stickySideBarContainer'}
                                >
                                    <Flex direction="column" rowGap={20}>
                                        <View UNSAFE_className="reportCardHeader" alignSelf="center">
                                            <Text>REPORT CARD</Text>
                                        </View>
                                        <Flex
                                            direction="row"
                                            columnGap="size-200"
                                            justifyContent="center"
                                            alignItems={'center'}
                                        >
                                            <Flex direction="column" rowGap={20}>
                                                <Flex direction="row" columnGap="size-200" justifyContent="center">
                                                    <div
                                                        onClick={() => handleTabSelectionChange('salt')}
                                                        className={'reportCardItemWrapper'}
                                                    >
                                                        <Flex direction="column">
                                                            <Text UNSAFE_className="reportCardSectionTitles">
                                                                SALTINESS
                                                            </Text>
                                                            <Text UNSAFE_className="reportCardGradeLetter">
                                                                {getGrades().salt.grade}
                                                            </Text>
                                                            <Text
                                                                UNSAFE_className="reportCardScore"
                                                                UNSAFE_style={{ color: getGrades().salt.color }}
                                                            >
                                                                <Flex direction="row" justifyContent="center">
                                                                    {parseFloat(deck.saltRating).toFixed(1)}
                                                                    <ContextualHelp variant="help">
                                                                        <Heading>Saltiness</Heading>
                                                                        <Content>
                                                                            <Text>
                                                                                This is purely a measure of how
                                                                                emotionally distressing your deck is to
                                                                                your opponents.
                                                                                <p />
                                                                                This score does not affect the power
                                                                                level score - that is an entirely
                                                                                different set of criteria.
                                                                            </Text>
                                                                        </Content>
                                                                    </ContextualHelp>
                                                                </Flex>
                                                            </Text>
                                                        </Flex>
                                                    </div>
                                                    <div
                                                        onClick={() => handleTabSelectionChange('categories')}
                                                        className={'reportCardItemWrapper'}
                                                    >
                                                        <Flex direction="column">
                                                            <Text UNSAFE_className="reportCardSectionTitles">
                                                                INTERACTION
                                                            </Text>
                                                            <Text UNSAFE_className="reportCardGradeLetter">
                                                                {getGrades().threat.grade}
                                                            </Text>
                                                            <Text
                                                                UNSAFE_className="reportCardScore"
                                                                UNSAFE_style={{ color: getGrades().threat.color }}
                                                            >
                                                                {parseInt(
                                                                    deck?.details?.powerLevel?.scoring?.interaction
                                                                        ?.score
                                                                )}
                                                            </Text>
                                                        </Flex>
                                                    </div>
                                                    {/*</Flex>*/}
                                                    {/*<Flex direction="row" columnGap="size-200" justifyContent="center">*/}
                                                    <div
                                                        onClick={() => handleTabSelectionChange('powerLevel')}
                                                        className={'reportCardItemWrapper'}
                                                    >
                                                        <Flex direction="column">
                                                            <Text UNSAFE_className="reportCardSectionTitles">
                                                                WINCONS
                                                            </Text>
                                                            <Text UNSAFE_className="reportCardGradeLetter">
                                                                {getGrades().wincons.grade}
                                                            </Text>
                                                            <Text
                                                                UNSAFE_className="reportCardScore"
                                                                UNSAFE_style={{ color: getGrades().wincons.color }}
                                                            >
                                                                {deck.comboRating}
                                                            </Text>
                                                        </Flex>
                                                    </div>
                                                    <div
                                                        onClick={() => handleTabSelectionChange('synergy')}
                                                        className={'reportCardItemWrapper'}
                                                    >
                                                        <Flex direction="column">
                                                            <Text UNSAFE_className="reportCardSectionTitles">
                                                                SYNERGY
                                                            </Text>
                                                            <Text UNSAFE_className="reportCardGradeLetter">
                                                                {getGrades().synergy.grade}
                                                            </Text>
                                                            <Text
                                                                UNSAFE_className="reportCardScore"
                                                                UNSAFE_style={{ color: getGrades().synergy.color }}
                                                            >
                                                                {parseFloat(
                                                                    deck?.details?.synergy?.scoring?.totalScore || 0
                                                                ).toFixed(1)}
                                                            </Text>
                                                        </Flex>
                                                    </div>
                                                </Flex>
                                            </Flex>
                                        </Flex>

                                        <div
                                            onClick={() => handleTabSelectionChange('archetypes')}
                                            className={'reportCardItemWrapper'}
                                        >
                                            <Flex
                                                direction={'column'}
                                                justifyContent="center"
                                                alignItems={'center'}
                                                rowGap={5}
                                                marginTop={10}
                                            >
                                                <Text UNSAFE_className="reportCardSectionTitles">FLAVOR PROFILE</Text>
                                                {/*<Flex direction={'row'} columnGap={20}>*/}
                                                <img src={'/resources/saltyHand.png'} width={100} />
                                                <Text UNSAFE_className="reportCardScore">
                                                    {getPrettyArchetypeLabel(deck?.archetypeMajor)}{' '}
                                                    <span style={{ color: '#9e9e9e' }}>/</span>{' '}
                                                    {getPrettyArchetypeLabel(deck?.archetypeMinor)}
                                                </Text>
                                                {/*</Flex>*/}
                                            </Flex>
                                        </div>

                                        {allowManabase && (
                                            <div
                                                onClick={() => handleTabSelectionChange('manabase')}
                                                className={'reportCardItemWrapper'}
                                            >
                                                <Flex
                                                    direction="column"
                                                    UNSAFE_className={'nutritionFactsLabelBoxBorder'}
                                                    maxWidth="size-6000"
                                                >
                                                    <Flex
                                                        direction="row"
                                                        columnGap="size-200"
                                                        justifyContent="center"
                                                        alignItems={'center'}
                                                    >
                                                        <span className="nutritionFactsLabelBoxHeader">
                                                            Nutrition facts
                                                        </span>
                                                    </Flex>
                                                    <div
                                                        style={{
                                                            height: 5,
                                                            backgroundColor: '#FFFFFF',
                                                            marginLeft: 20,
                                                            marginRight: 20,
                                                        }}
                                                    />
                                                    <Flex
                                                        direction="row"
                                                        columnGap="size-200"
                                                        justifyContent="center"
                                                        alignItems={'center'}
                                                    >
                                                        <Flex
                                                            direction={'column'}
                                                            UNSAFE_className={'nutritionFactsLabelBoxContents'}
                                                            rowGap={2}
                                                        >
                                                            <span className={'nutritionFactsLabelBoxDailyValue'}>
                                                                % Daily Value
                                                            </span>
                                                            <div
                                                                style={{
                                                                    height: 1,
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                            />
                                                            <Flex direction={'row'}>
                                                                <div>
                                                                    Manafixing{' '}
                                                                    {parseFloat(
                                                                        deck?.details?.manabase?.manaFixingScore
                                                                    ).toFixed(0)}
                                                                    g
                                                                </div>
                                                                {/*<div className={'nutritionFactsLabelBoxAmount'}>*/}
                                                                {/*    g*/}
                                                                {/*</div>*/}
                                                                <div style={{ width: 100 }} />
                                                                <div className={'nutritionFactsLabelBoxPercentage'}>
                                                                    {parseFloat(
                                                                        deck?.details?.manabase?.percentages?.manaFixing
                                                                    ).toFixed(0)}
                                                                    %
                                                                </div>
                                                            </Flex>
                                                            <div
                                                                style={{
                                                                    height: 1,
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                            />
                                                            <Flex direction={'row'}>
                                                                <div>
                                                                    Playing on curve percentage{' '}
                                                                    {parseFloat(
                                                                        deck?.details?.manabase?.curveChart?.curveAppeal
                                                                    ).toFixed(0)}
                                                                    g
                                                                </div>
                                                                {/*<div className={'nutritionFactsLabelBoxAmount'}>*/}
                                                                {/*    g*/}
                                                                {/*</div>*/}
                                                                <div style={{ width: 100 }} />
                                                                <div className={'nutritionFactsLabelBoxPercentage'}>
                                                                    {parseFloat(
                                                                        deck?.details?.manabase?.percentages?.curve
                                                                    ).toFixed(0)}
                                                                    %
                                                                </div>
                                                            </Flex>
                                                            <div
                                                                style={{
                                                                    height: 1,
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                            />
                                                            <Flex direction={'row'} width={'100%'}>
                                                                <div>
                                                                    Manabase Quality{' '}
                                                                    {parseFloat(
                                                                        deck?.details?.manabase?.curveChart?.quality
                                                                    ).toFixed(0)}
                                                                    g
                                                                </div>
                                                                {/*<div className={'nutritionFactsLabelBoxAmount'}>*/}
                                                                {/*    {parseInt(*/}
                                                                {/*        deck?.details?.manabase?.details*/}
                                                                {/*            ?.manaProducerQualityScore |`| '0'*/}
                                                                {/*    )}*/}
                                                                {/*    g*/}
                                                                {/*</div>*/}
                                                                <div style={{ width: 100 }} />
                                                                <div className={'nutritionFactsLabelBoxPercentage'}>
                                                                    {parseFloat(
                                                                        deck?.details?.manabase?.percentages?.quality
                                                                    ).toFixed(0)}
                                                                    %
                                                                </div>
                                                            </Flex>
                                                            <div
                                                                style={{
                                                                    height: 1,
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                            />
                                                            <Flex direction={'row'}>
                                                                <div style={{ color: '#FF0000', paddingLeft: '20px' }}>
                                                                    [BONUS] Early game acceleration{' '}
                                                                    <span style={{ color: '#FF0000' }}>
                                                                        {
                                                                            deck?.details?.manabase?.curveChart
                                                                                ?.accelerationBonus
                                                                        }
                                                                        g
                                                                    </span>
                                                                    <span style={{ color: '#000000' }}></span>
                                                                </div>
                                                                {/*<div className={'nutritionFactsLabelBoxAmount'}>*/}
                                                                {/*    g*/}
                                                                {/*</div>*/}
                                                                <div style={{ width: 100 }} />
                                                                <div
                                                                    className={'nutritionFactsLabelBoxPercentage'}
                                                                ></div>
                                                            </Flex>
                                                            <div
                                                                style={{
                                                                    height: 1,
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                            />
                                                            <Flex direction={'row'} width={'100%'}>
                                                                <div style={{ color: '#ff9000' }}>
                                                                    <b>TOTAL CALORIES</b>
                                                                </div>
                                                                <div
                                                                    className={'nutritionFactsLabelBoxAmount'}
                                                                    style={{ marginBottom: 10, color: '#ff9000' }}
                                                                >
                                                                    {parseInt(
                                                                        deck?.details?.manabase?.totalCalories || '0'
                                                                    ).toFixed(0)}
                                                                    g
                                                                </div>
                                                                <div style={{ width: 100 }} />
                                                                <div
                                                                    className={'nutritionFactsLabelBoxPercentage'}
                                                                    style={{ marginBottom: 10, color: '#ff9000' }}
                                                                >
                                                                    {parseFloat(
                                                                        deck?.details?.manabase?.percentages?.overall
                                                                    ).toFixed(0)}
                                                                    %
                                                                </div>
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </div>
                                        )}
                                        {deck?.manuel && (
                                            <>
                                                {/*<Flex direction="row" width="100%" marginBottom={5} marginTop={30}>*/}
                                                {/*    {reviewHeaderList.map((item) => {*/}
                                                {/*        return (*/}
                                                {/*            <div*/}
                                                {/*                key={`gridCellReviewHeader_${item.id}`}*/}
                                                {/*                style={{ fontWeight: 'bold', fontSize: '.7rem', ...item.style }}*/}
                                                {/*            >*/}
                                                {/*                {item.label}*/}
                                                {/*            </div>*/}
                                                {/*        );*/}
                                                {/*    })}*/}
                                                {/*</Flex>*/}
                                                <div className={'reviewContainer'}>
                                                    <Flex direction="column" marginTop={110}>
                                                        <Text UNSAFE_className="perceivedLabelHeader" marginStart={130}>
                                                            {timeStamp}
                                                        </Text>
                                                        <Text UNSAFE_className="perceivedLabelHeader" marginTop={40}>
                                                            {comment}
                                                        </Text>
                                                        <Text
                                                            UNSAFE_className="perceivedLabel"
                                                            UNSAFE_style={{ color: '#000000' }}
                                                            // UNSAFE_style={{ color: getGrades().powerlevel.color }}
                                                        >
                                                            {deck?.manuel?.category}
                                                        </Text>
                                                    </Flex>
                                                </div>
                                            </>
                                        )}
                                    </Flex>
                                </View>
                            </div>
                            <div
                                style={{
                                    display: 'initial',
                                    flex: '3 1 350px',
                                    // marginBottom: '10px',
                                    width: 350,
                                    minWidth: 350,
                                    // maxWidth: 500,
                                }}
                            >
                                <View
                                    flex={1}
                                    borderWidth="thin"
                                    borderColor="light"
                                    borderRadius="medium"
                                    // padding="size-200"
                                    paddingTop="size-0"
                                    backgroundColor="gray-75"
                                    minHeight="0px"
                                >
                                    <Tabs
                                        defaultSelectedKey="powerLevel"
                                        selectedKey={tab}
                                        isEmphasized
                                        UNSAFE_style={{ padding: 0 }}
                                        onSelectionChange={handleTabSelectionChange}
                                        id={'tabPanel'}
                                    >
                                        <TabList
                                            marginTop="20px"
                                            // marginEnd="10px"
                                            marginStart="10px"
                                            UNSAFE_className={'stickyTabBarContainer'}
                                        >
                                            <Item key="archetypes">
                                                <HotFixes size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">ARCHETYPES</Text>
                                            </Item>
                                            <Item key="categories">
                                                <Servers size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">CATEGORIES</Text>
                                            </Item>
                                            <Item key="combos">
                                                <FlashOn size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">COMBOS</Text>
                                            </Item>
                                            <Item key="list">
                                                <Bookmark size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">DECKLIST</Text>
                                            </Item>
                                            <Item key="manabase">
                                                <ColorPalette size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">MANABASE</Text>
                                            </Item>
                                            <Item key="powerLevel">
                                                <GraphBarVertical size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">POWER LEVELS</Text>
                                            </Item>
                                            <Item key="salt">
                                                <Beaker size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">SALTINESS</Text>
                                            </Item>
                                            <Item key="synergy">
                                                <DataMapping size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">SYNERGY</Text>
                                            </Item>
                                            <Item key="upgrades">
                                                <Gauge4 size={'XS'} />
                                                <Text UNSAFE_className="previewTabLabel">UPGRADES</Text>
                                            </Item>
                                        </TabList>
                                        <TabPanels marginTop="10px" UNSAFE_style={{ paddingLeft: 10, paddingRight: 5 }}>
                                            <Item key="powerLevel">
                                                <PowerLevelPane />
                                            </Item>
                                            <Item key="upgrades">
                                                <UpgradelizerPane />
                                            </Item>
                                            <Item key="manabase">
                                                <ManabasePane />
                                            </Item>
                                            <Item key="salt">
                                                <SaltPane />
                                            </Item>
                                            <Item key="archetypes">
                                                <ArchetypesPane />
                                            </Item>
                                            <Item key="synergy">
                                                <SynergyPane />
                                            </Item>
                                            <Item key="combos">
                                                <CombosPane />
                                            </Item>
                                            <Item key="categories">
                                                <CategoriesPane />
                                            </Item>
                                            <Item key="list">
                                                <DeckListPane />
                                            </Item>
                                        </TabPanels>
                                    </Tabs>
                                </View>
                            </div>
                        </Flex>
                    </>
                </Flex>
            )) || (
                <>
                    {(!isFailedFetch && (
                        <View
                            minHeight="size-6000"
                            alignSelf="center"
                            height="size-6000"
                            UNSAFE_style={{ verticalAlign: 'middle' }}
                        >
                            <Flex
                                direction="column"
                                UNSAFE_style={{
                                    margin: 0,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                                alignContent="center"
                                alignItems="center"
                            >
                                <ProgressCircle isIndeterminate="true" size="L" />
                                <Text UNSAFE_className="previewDeckTitleLabel">Loading...</Text>
                            </Flex>
                        </View>
                    )) || (
                        <>
                            <View
                                minHeight="size-6000"
                                alignSelf="center"
                                height="size-6000"
                                UNSAFE_style={{ verticalAlign: 'middle' }}
                            >
                                <Flex
                                    direction="column"
                                    UNSAFE_style={{
                                        margin: 0,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    <Text UNSAFE_className="previewDeckTitleLabel">Whoops...</Text>
                                    <Text>We can't seem to find this deck!</Text>
                                </Flex>
                            </View>
                        </>
                    )}
                </>
            )}
        </Flex>
    );
}
