import React from 'react';
import { Flex, Text } from '@adobe/react-spectrum';
import './Bot.css';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';

export function Bot() {
    return (
        <Flex direction="column">
            <Helmet>
                <title>Commandersalt.com Discord Bot!</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href="https://www.commandersalt.com/faq" />
                <meta name="description" content="Commandersalt.com Discord Bot!" />
                <meta name="twitter:title" content="Commandersalt.com - Discord Bot" />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content="Commandersalt.com - Discord Bot" />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content="Commandersalt.com Discord Bot!" />
            </Helmet>
            <BreadCrumbs />
            <Flex
                direction="column"
                maxWidth={800}
                alignSelf={'center'}
                marginTop={20}
                columnGap={50}
                marginStart={10}
                marginEnd={10}
                marginBottom={0}
                wrap
            >
                <Flex direction={'row'} justifyContent={'space-around'} width={'100%'}>
                    <img src="resources/botvideo.gif" />
                </Flex>
                <div style={{ height: '30px' }} />
                <Text UNSAFE_style={{ fontSize: '16px', fontWeight: 'bolder' }}>Check out our new bot!</Text>
                <Text>Currently the following commands are available:</Text>
                <ul>
                    <li>
                        <b>/check</b>: Use this with a url to your deck to get an estimated Power Level score as well as
                        a Salt score
                    </li>
                </ul>
                More coming soon!
                <a
                    style={{ color: '#FF0000' }}
                    href="https://discord.com/oauth2/authorize?client_id=1130881153998540800&permissions=2147483648&scope=bot"
                >
                    You can install the bot here.
                </a>
            </Flex>
        </Flex>
    );
}
