import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';

export const previewSlice = createSlice({
    name: 'preview',
    initialState: {
        isShowing: false,
        previewDeck: null,
        previewIsFetching: false,
        cards: {},
        cache: {},
        isFailedFetch: false,
    },
    reducers: {
        setPreviewDeck: (state, action) => {
            state.previewDeck = action.payload;
            state.isShowing = true;
            state.previewIsFetching = false;
            state.isFailedFetch = false;

            state.cards = {};

            if (Object.keys(state?.previewDeck?.cards || {}).length > 0) {
                state.cards = state.previewDeck.cards;
            }
            // state?.previewDeck?.cards?.map((card) => {
            //     state.cards[card?.id] = card;
            // });

            if (!state.cache.hasOwnProperty(action.payload.id)) {
                // console.log(`CACHING DECK ID: ${action.payload.id}`);
                state.cache[action.payload.id] = action.payload;
            }
        },
        setPreviewIsShowingFalse: (state) => {
            state.previewDeck = null;
            state.isShowing = false;
            state.previewIsFetching = false;
            state.isFailedFetch = false;
        },
        setPreviewIsShowingTrue: (state) => {
            state.previewDeck = null;
            state.previewIsFetching = true;
            state.isShowing = true;
            state.isFailedFetch = false;
            ``;
        },
        setPreviewLoadFailed: (state) => {
            state.previewDeck = null;
            state.previewIsFetching = false;
            state.isShowing = true;
            state.isFailedFetch = true;
        },
    },
});

// Actions
export const { setPreviewDeck, setPreviewIsShowingFalse, setPreviewIsShowingTrue, setPreviewLoadFailed } =
    previewSlice.actions;

export const fetchPreviewDeck = (id) => (dispatch) => {
    dispatch(setPreviewIsShowingTrue());

    // DynamoConnector.getDeckForPreview(
    //     id,
    //     (results) => {
    //         dispatch(setPreviewDeck(results));
    //     },
    //     () => {
    //         dispatch(setPreviewLoadFailed());
    //     }
    // );
};

// Selectors
export const isPreviewShowing = (state) => state.preview.isShowing;

export default previewSlice.reducer;
