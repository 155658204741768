import React from 'react';
import { useSelector } from 'react-redux';
import { BaseDeckPane } from './BaseDeckPane';

export function DeckListPane() {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);

    const CATEGORY_CONSTANTS = {
        COMMANDERS: {
            enum: 'COMMANDERS',
            color: '#00a600',
        },
        COMPANIONS: {
            enum: 'COMPANIONS',
            color: '#00a600',
        },
        PLANESWALKERS: {
            enum: 'PLANESWALKERS',
            color: '#F8F8FF',
        },
        CREATURES: {
            enum: 'CREATURES',
            color: '#ff00dc',
        },
        SORCERIES: {
            enum: 'SORCERIES',
            color: '#00ff3c',
        },
        INSTANTS: {
            enum: 'INSTANTS',
            color: '#0446c5',
        },
        ENCHANTMENTS: {
            enum: 'ENCHANTMENTS',
            color: '#ff7300',
        },
        ARTIFACTS: {
            enum: 'ARTIFACTS',
            color: '#faed04',
        },
        BATTLES: {
            enum: 'BATTLES',
            color: 'rgba(250,61,4,0.89)',
        },
        LANDS: {
            enum: 'LANDS',
            color: '#653926',
        },
    };

    const getCategories = () => {
        const list = {};

        Object.keys(CATEGORY_CONSTANTS)?.map((category) => {
            list[category] = {
                label: `${category?.toUpperCase()}`,
                id: category,
                count: 0,
                list: [],
                color: CATEGORY_CONSTANTS?.[category]?.color || `#FFFFFF`,
            };
        });

        Object.keys(cards).map((id) => {
            const card = cards[id];
            let category = '';

            if (card.isCommander) {
                category = CATEGORY_CONSTANTS.COMMANDERS.enum;
            } else if (deck?.companions?.includes(card?.name)) {
                category = CATEGORY_CONSTANTS.COMPANIONS.enum;
            } else if (card?.types?.includes(`planeswalker`)) {
                category = CATEGORY_CONSTANTS.PLANESWALKERS.enum;
            } else if (card?.types?.includes(`creature`)) {
                category = CATEGORY_CONSTANTS.CREATURES.enum;
            } else if (card?.types?.includes(`sorcery`)) {
                category = CATEGORY_CONSTANTS.SORCERIES.enum;
            } else if (card?.types?.includes(`instant`)) {
                category = CATEGORY_CONSTANTS.INSTANTS.enum;
            } else if (card?.types?.includes(`battle`)) {
                category = CATEGORY_CONSTANTS.BATTLES.enum;
            } else if (card?.types?.includes(`land`)) {
                category = CATEGORY_CONSTANTS.LANDS.enum;
            } else if (card?.types?.includes(`artifact`)) {
                category = CATEGORY_CONSTANTS.ARTIFACTS.enum;
            } else if (card?.types?.includes(`enchantment`)) {
                category = CATEGORY_CONSTANTS.ENCHANTMENTS.enum;
            }

            if (category) {
                list[category].list.push(card);
                list[category].count += 1;
            }
        });

        Object.keys(CATEGORY_CONSTANTS)?.map((category) => {
            if (list[category]?.count < 1) {
                delete list[category];
            } else {
                list[category].list = list[category].list.sort((a, b) => {
                    if (b?.name < a?.name) {
                        return 1;
                    }

                    return -1;
                });
            }
        });

        return list;
    };

    return (
        <BaseDeckPane
            title={'Deck List'}
            fieldName="name"
            content={<div />}
            showScore={false}
            categories={getCategories()}
            skipSubCategories={true}
        ></BaseDeckPane>
    );
}
