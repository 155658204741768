import React, { useState, useCallback } from 'react';
import {
    Flex,
    TextField,
    TextArea,
    ActionButton,
    Text,
    Item,
    ComboBox,
    View,
    Heading,
    Divider,
    Menu,
    Section,
    MenuTrigger,
    ProgressBar,
    Content,
    ContextualHelp,
} from '@adobe/react-spectrum';
import './Card.css';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { fetchList, resetSearchField } from '../../data/redux/slices/searchSlice';
import { fetchCardById } from '../../data/redux/slices/cardsSlice';
import { getDomainPrefix } from '../../data/DynamoConnector';
import { getPrettyLabel } from '../../data/CategoryPrettyDisplayUtils';
import debounce from 'lodash.debounce';

export function Card() {
    const dispatch = useDispatch();
    const cardsSearchList = useSelector((state) => state.search.cards);
    const searchFetching = useSelector((state) => state.search.isFetching);
    const cardIsFetching = useSelector((state) => state.cards.isFetching);
    const card = useSelector((state) => state.cards.currentCard);

    const [cardLookupId, setCardLookupId] = useState({ id: '', name: '' });

    const handleCardSelectionChange = (evn) => {
        if (evn) {
            setCardLookupId(evn);
            dispatch(fetchCardById(evn));
        }
    };

    const debouncedFilter = useCallback(
        debounce((query) => {
            console.log(`running`);
            if (query.length > 3 && query !== cardLookupId.name && query.id !== cardLookupId.id) {
                dispatch(fetchList(query, 'cards'));
            }
        }, 500),
        []
    );

    const handleCardListChange = (evn) => {
        debouncedFilter(evn);
    };

    const getContextBlob = (face, contextIds) => {
        const retValue = {
            line: null,
            part: null,
            condition: null,
            preCondition: null,
        };

        // console.log(JSON.stringify(face, null, 4));
        face?.context?.lines?.map((line) => {
            if (!retValue.line) {
                if (line.id === contextIds.line) {
                    retValue.line = line;

                    line?.parts?.map((part) => {
                        if (!retValue.part) {
                            if (part.id === contextIds.part) {
                                retValue.part = part;

                                part.conditions?.map((condition) => {
                                    if (!retValue.condition) {
                                        if (condition.id === contextIds.condition) {
                                            retValue.condition = condition;
                                        } else {
                                            condition?.results?.map((result) => {
                                                if (!retValue.condition) {
                                                    if (result.id === contextIds.condition) {
                                                        retValue.condition = result;
                                                        retValue.preCondition = condition;
                                                    }
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });

        return retValue;
    };

    const faceIdList = () => {
        return Object.keys(card?.faces || {}).map((id) => {
            return {
                id: id,
                name: card.faces[id].name,
            };
        });
    };

    // let _wtf = faceIdList()?.[0]?.id;
    let [faceId, setFaceId] = React.useState(faceIdList()?.[0]?.id || null);

    if (!card && faceId) {
        setFaceId(null);
    }

    if (card && !faceId) {
        setFaceId(card.frontFaceId);
    }

    const face = card?.faces?.[faceId] || null;

    const idsUsed = {};

    const buildDataBlock = (item, _condition = null, _line = null) => {
        let contextBlob = {};

        if (!_condition || !_line) {
            contextBlob = getContextBlob(face, item.contextIds);
        } else {
            contextBlob = { condition: _condition, line: _line };
        }

        idsUsed[contextBlob.line.id] = true;

        const condition =
            // contextBlob.preCondition ||
            contextBlob.condition;

        const _lineRaw = contextBlob.line.raw;
        const startIndex = _lineRaw.toLowerCase().indexOf(condition.text);

        const preText = _lineRaw.substring(0, startIndex);
        const conditionText = _lineRaw.substring(startIndex, preText.length + condition.text.length);
        const postText = _lineRaw.substring(preText.length + condition.text.length);

        return (
            <Flex direction={'column'} key={`${condition.id}`} margin={10}>
                <div className={'propertySection'}>
                    <span className={'categoriesPropertyLabel'}>TEXT:</span>
                    <div className={'indent'}>
                        <span className={'categoriesValueLabel'}>{preText}</span>
                        <span className={'categoriesValueLabel'} style={{ color: '#ffdc4b' }}>
                            {conditionText}
                        </span>
                        <span className={'categoriesValueLabel'}>{postText}</span>
                    </div>
                </div>

                {/*<div className={'propertySection'}>*/}
                {/*    <span className={'categoriesPropertyLabel'}>*/}
                {/*        <span*/}
                {/*            className={'categoriesValueLabel'}*/}
                {/*        >*/}
                {/*            BASE SCORE:*/}
                {/*        </span>*/}
                {/*    </span>*/}
                {/*    <div className={'indent'}>*/}
                {/*        <span*/}
                {/*            className={'categoriesValueLabel'}*/}
                {/*        >*/}
                {/*            {item.score}*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={'propertySection'}>
                    <span className={'categoriesPropertyLabel'}>EFFECT TYPE:</span>
                    <div className={'indent'}>
                        <span className={'categoriesValueLabel'}>{contextBlob.line.effectType?.toUpperCase()}</span>
                    </div>
                </div>

                <div className={'propertySection'}>
                    <span className={'categoriesPropertyLabel'}>IS SELF REFERENCE:</span>
                    <div className={'indent'}>
                        <span className={'categoriesValueLabel'}>
                            {contextBlob.condition.isSelfReference?.toString()?.toUpperCase()}
                        </span>
                    </div>
                </div>

                {contextBlob.line.effectType === 'trigger' && (
                    <>
                        <div className={'propertySection'}>
                            <span className={'categoriesPropertyLabel'}>IS SINGLE TRIGGER ONLY:</span>
                            <div className={'indent'}>
                                <span className={'categoriesValueLabel'}>
                                    {contextBlob.condition.isSingleTriggerOnly?.toString()?.toUpperCase()}
                                </span>
                            </div>
                        </div>

                        <div className={'propertySection'}>
                            <span className={'categoriesPropertyLabel'}>IS ENTER OR LEAVE EFFECT:</span>
                            <div className={'indent'}>
                                <span className={'categoriesValueLabel'}>
                                    {contextBlob.condition.isEnterOrLeaveEffect?.toString()?.toUpperCase()}
                                </span>
                            </div>
                        </div>
                    </>
                )}

                <div className={'propertySection'}>
                    <span className={'categoriesPropertyLabel'}>ZONES:</span>
                    <div>
                        <span className={'categoriesPropertyLabel indent'}>FROM:</span>
                    </div>
                    <div>
                        <span className={'categoriesValueLabel doubleIndent'}>
                            {contextBlob.condition.zones.from?.toString()?.toUpperCase()}
                        </span>
                    </div>
                    <div>
                        <span className={'categoriesPropertyLabel indent'}>TO:</span>
                    </div>
                    <div>
                        <span className={'categoriesValueLabel doubleIndent'}>
                            {contextBlob.condition.zones.to?.toString()?.toUpperCase()}
                        </span>
                    </div>
                </div>

                <div className={'propertySection'}>
                    <span className={'categoriesPropertyLabel'}>PLAYER TYPE:</span>
                    <div className={'indent'}>
                        <span className={'categoriesValueLabel'}>
                            {contextBlob.condition.player.type?.toUpperCase()}
                        </span>
                    </div>
                </div>

                {contextBlob.condition.player.actions.length > 0 && (
                    <div className={'propertySection'}>
                        <span className={'categoriesPropertyLabel'}>PLAYER ACTIONS:</span>
                        {contextBlob.condition.player.actions.map((action) => {
                            return (
                                <div>
                                    <span className={'categoriesValueLabel indent'}>
                                        {action.toString()?.toUpperCase()}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}

                {contextBlob.condition.card.this.actions.length > 0 && (
                    <>
                        <div className={'propertySection'}>
                            <span className={'categoriesPropertyLabel'}>CARD ACTIONS:</span>
                            {contextBlob.condition.card.this.actions.map((action) => {
                                return (
                                    <div>
                                        <span className={'categoriesValueLabel indent'}>
                                            {action.toString()?.toUpperCase()}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}

                {contextBlob.condition.card.dependencies.actions.length > 0 && (
                    <>
                        <div className={'propertySection'}>
                            <span className={'categoriesPropertyLabel'}>ACTION DEPENDENCIES (from other cards):</span>
                            {contextBlob.condition.card.dependencies.actions.map((action) => {
                                return (
                                    <div>
                                        <span className={'categoriesValueLabel indent'}>
                                            {action.toString()?.toUpperCase()}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}

                {Object.keys(condition.qualifiers).length > 0 && (
                    <div>
                        <span className={'categoriesPropertyLabel'}>QUALIFIERS:</span>
                        {Object.keys(condition.qualifiers).map((qualifierKey) => {
                            const qualifierObject = condition.qualifiers[qualifierKey];
                            return (
                                <div>
                                    <span className={'categoriesPropertyLabel indent'}>
                                        {qualifierKey?.toUpperCase()}
                                    </span>
                                    {qualifierObject.list.map((qualifierItem) => {
                                        return (
                                            <div>
                                                <span className={'categoriesValueLabel doubleIndent'}>
                                                    {qualifierItem.toString()?.toUpperCase()}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                )}
            </Flex>
        );
    };

    const getIsPowerLevelCategory = (category) => {
        switch (category) {
            case 'tutor':
            case 'cantrip':
            case 'recursion':
            case 'removal':
            case 'counterspell':
            case 'stax':
            case 'taxes':
            case 'graveyard':
            case 'evasion':
            case 'fastmana':
            case 'ramp':
            case 'wincon':
                return true;
            default:
                return false;
        }
    };

    return (
        <Flex direction="column">
            <Helmet>
                <title>Cards</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href="https://www.commandersalt.com/faq" />
                <meta name="description" content="Frequently asked questions" />
                <meta name="twitter:title" content="Commandersalt.com - FAQ" />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content="Commandersalt.com - FAQ" />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content="Frequently asked questions" />
            </Helmet>
            <BreadCrumbs />
            <Flex
                direction="column"
                alignItems="baseline"
                height="100%"
                UNSAFE_style={{ width: 'calc(100dvw - 20px)' }}
                gap="size-100"
                marginEnd={20}
                alignSelf={'center'}
            >
                <View width={'100%'}>
                    <Flex
                        direction={'row'}
                        justifyContent={'space-between'}
                        marginStart={20}
                        marginTop={50}
                        marginEnd={20}
                        marginBottom={20}
                    >
                        <Heading>{card?.name}</Heading>
                        <ComboBox
                            label="Card Lookup"
                            items={cardsSearchList}
                            maxWidth={400}
                            width={'100%'}
                            // defaultInputValue={card?.name || ''}
                            onInputChange={handleCardListChange}
                            loadingState={searchFetching}
                            onSelectionChange={handleCardSelectionChange}
                            // onLoadMore={list.loadMore}
                        >
                            {(item) => <Item>{item.name}</Item>}
                        </ComboBox>
                    </Flex>
                </View>
                <Divider marginStart={20} size={'M'} marginBottom={50} />
                {(face && (
                    <Flex
                        direction="row"
                        justifyContent={'space-between'}
                        columnGap={20}
                        marginTop={10}
                        marginStart={10}
                        marginEnd={100}
                        marginBottom={0}
                        width={'100%'}
                        rowGap={20}
                        wrap
                    >
                        <div
                            style={{
                                display: 'initial',
                                flex: '3 1 350px',
                                // marginBottom: '10px',
                                width: 350,
                                minWidth: 350,
                                maxWidth: 350,
                            }}
                        >
                            <View
                                flex={1}
                                borderWidth="thin"
                                borderColor="light"
                                borderRadius="medium"
                                padding="size-200"
                                backgroundColor="gray-75"
                                minHeight="0px"
                                // UNSAFE_className={'stickySideBarContainer'}
                            >
                                <Flex direction={'column'}>
                                    <Flex
                                        direction={'row'}
                                        marginBottom={20}
                                        justifyContent={'center'}
                                        justifySelf={'center'}
                                    >
                                        <span
                                            className={'categoriesPropertyLabel'}
                                            style={{ marginTop: 10, marginRight: 10 }}
                                        >
                                            FACE/SIDE:
                                        </span>
                                        <MenuTrigger>
                                            <ActionButton
                                                width={200}
                                                UNSAFE_className={'synergyMenuBoxActionLabel'}
                                                isDisabled={Object.keys(card?.faces || {})?.length < 2}
                                            >
                                                {card?.faces?.[faceId]?.name}
                                            </ActionButton>
                                            <Menu
                                                UNSAFE_className={'synergyMenuBox'}
                                                items={faceIdList()}
                                                selectedKeys={faceId}
                                                onSelectionChange={(evn) => {
                                                    setFaceId(evn?.currentKey);
                                                }}
                                                selectionMode="single"
                                            >
                                                {(item) => <Item>{item.name}</Item>}
                                            </Menu>
                                        </MenuTrigger>
                                    </Flex>
                                    <img src={face.metadata.image_uris.normal} width={300} />
                                    <Flex
                                        direction={'row'}
                                        marginBottom={20}
                                        justifyContent={'center'}
                                        justifySelf={'center'}
                                        marginTop={20}
                                    >
                                        <a
                                            style={{ fontSize: '11px' }}
                                            href={`${getDomainPrefix()}/details?id=${card.id}&isCard=true`}
                                            target={'_blank'}
                                        >
                                            View raw JSON blob for this card
                                        </a>
                                    </Flex>
                                    <Flex
                                        direction={'row'}
                                        marginBottom={20}
                                        justifyContent={'center'}
                                        justifySelf={'center'}
                                        marginTop={20}
                                    >
                                        <span className={'disclaimerText'}>
                                            Does something look wrong with this card data? Please feel free to email me
                                            via the contact page, or hop into our Discord!
                                        </span>
                                    </Flex>
                                </Flex>
                            </View>
                        </div>
                        <div
                            style={{
                                display: 'initial',
                                flex: '3 1 350px',
                                // marginBottom: '10px',
                                width: 350,
                                minWidth: 350,
                                // maxWidth: 500,
                            }}
                        >
                            <Flex direction={'column'} rowGap={20}>
                                <View
                                    flex={1}
                                    borderWidth="thin"
                                    borderColor="light"
                                    borderRadius="medium"
                                    padding="size-200"
                                    // paddingTop="size-0"
                                    backgroundColor="gray-75"
                                    minHeight="0px"
                                >
                                    <h1>Categories</h1>
                                    <Flex direction={'column'}>
                                        <>
                                            {Object.keys(face.scoring).map((scoringKey) => {
                                                switch (scoringKey) {
                                                    case 'slow':
                                                        break;
                                                    default:
                                                        const scoringObject = face.scoring[scoringKey];
                                                        return (
                                                            <div className={'categoriesSection'}>
                                                                <div
                                                                    key={scoringKey}
                                                                    className={
                                                                        getIsPowerLevelCategory(scoringKey)
                                                                            ? 'categoriesLabelUsedInPowerLevels'
                                                                            : 'categoriesLabel'
                                                                    }
                                                                >
                                                                    {getPrettyLabel(scoringKey)?.toUpperCase()}
                                                                    {getIsPowerLevelCategory(scoringKey) && (
                                                                        <ContextualHelp variant="info" marginStart={5}>
                                                                            <Content>
                                                                                <Text>
                                                                                    This category is used in Power Level
                                                                                    calculations
                                                                                </Text>
                                                                            </Content>
                                                                        </ContextualHelp>
                                                                    )}
                                                                </div>
                                                                {scoringObject.map((item) => {
                                                                    return buildDataBlock(item);
                                                                })}
                                                            </div>
                                                        );
                                                }
                                            })}
                                        </>
                                    </Flex>
                                </View>
                                <View
                                    flex={1}
                                    borderWidth="thin"
                                    borderColor="light"
                                    borderRadius="medium"
                                    padding="size-200"
                                    // paddingTop="size-0"
                                    backgroundColor="gray-75"
                                    minHeight="0px"
                                >
                                    <h1>Uncategorized Lines</h1>
                                    <Flex direction={'column'}>
                                        <>
                                            {(face?.context?.lines || []).map((line) => {
                                                return (
                                                    <>
                                                        {!idsUsed.hasOwnProperty(line.id) && (
                                                            <div className={'categoriesSection'}>
                                                                <div
                                                                    key={`uncategorized_${line.id}`}
                                                                    className={'categoriesLabel'}
                                                                ></div>
                                                                {(line.parts || []).map((part) => {
                                                                    return (
                                                                        <>
                                                                            {(part.conditions || []).map(
                                                                                (condition) => {
                                                                                    return buildDataBlock(
                                                                                        null,
                                                                                        condition,
                                                                                        line
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </>
                                    </Flex>
                                </View>
                            </Flex>
                        </div>
                    </Flex>
                )) || (
                    <Flex direction={'column'} width={'100%'} height={'100%'}>
                        {cardIsFetching ? (
                            <View
                                minHeight="size-6000"
                                alignSelf="center"
                                height="size-6000"
                                UNSAFE_style={{ verticalAlign: 'middle' }}
                                maxWidth={600}
                                justifySelf={'center'}
                            >
                                <ProgressBar
                                    label="Loading..."
                                    UNSAFE_style={{ width: '100%', maxWidth: 600 }}
                                    isIndeterminate
                                />
                            </View>
                        ) : (
                            <View
                                minHeight="size-6000"
                                alignSelf="center"
                                height="size-6000"
                                UNSAFE_style={{ verticalAlign: 'middle' }}
                            >
                                <Flex
                                    direction="column"
                                    UNSAFE_style={{
                                        margin: 0,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    {/*<Text UNSAFE_className="previewDeckTitleLabel">Whoops...</Text>*/}
                                    <Text>To begin, search for a card in the text box above</Text>
                                </Flex>
                            </View>
                        )}
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
}
