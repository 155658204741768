import React, { useState } from 'react';
import { Well, Text, Flex, ActionButton, SearchField, Radio, RadioGroup, RangeSlider } from '@adobe/react-spectrum';
import './FilterPanel.css';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import { ManaSelector } from './ManaSelector';
import { useDebouncedCallback } from 'use-debounce';
import { getAnalytics, logEvent } from 'firebase/analytics';

export function FilterPanel(props) {
    // hooks
    const [isOpen, setIsOpen] = useState(true);
    const [query, setQuery] = useState('');
    const [queryType, setQueryType] = useState('commander');
    const [high, setHigh] = useState(props.filters?.range?.high || 10);
    const [low, setLow] = useState(props.filters?.range?.low || 10);
    const [colorFilters, setColorFilters] = useState(props?.filters?.colorFilters || null);

    const [changed, setChanged] = useState(false);

    const pushFiltersChangeUpstream = () => {
        let type = queryType;

        if (props?.restrictToCommander) {
            type = 'commander';
        }

        if (props?.restrictToAuthor) {
            type = 'author';
        }

        props?.onFiltersChange &&
            props.onFiltersChange({
                query,
                queryType: type,
                colorFilters,
                range: {
                    high,
                    low,
                },
            });
    };

    if (changed) {
        pushFiltersChangeUpstream();
        setChanged(false);
    }

    const handleQuerySubmit = () => {
        logEvent(getAnalytics(), `search_${queryType?.toUpperCase()}`);
        setChanged(true);
    };

    const handleRangeSliderChange = useDebouncedCallback((evn) => {
        logEvent(getAnalytics(), `search_${evn?.start}_${evn?.end}`);
        setHigh(evn.end);
        setLow(evn.start);
        setChanged(true);
    }, 500);

    const handleColorFiltersChange = useDebouncedCallback((evn) => {
        logEvent(getAnalytics(), `search_${evn?.colors?.toUpperCase()}`);
        setColorFilters(evn.colors);
        setChanged(true);
    }, 500);

    const toggleIsOpen = (val) => {
        setIsOpen(val);
        window.dispatchEvent(new Event('resize'));
    };

    return (
        <Flex direction="column" marginStart={20} marginEnd={20} marginTop={25} marginBottom={25}>
            <Flex direction="row" alignItems="start" columnGap="40px" width="100%">
                <ActionButton
                    onPress={() => toggleIsOpen(isOpen ? false : true)}
                    width="100%"
                    height="25px"
                    marginBottom="5px"
                    isQuiet={true}
                >
                    <Flex
                        direction="row"
                        width="100%"
                        UNSAFE_style={{ cursor: 'pointer' }}
                        onPress={() => toggleIsOpen(isOpen ? false : true)}
                    >
                        {isOpen ? <ChevronDown marginTop="5px" /> : <ChevronRight marginTop="5px" />}
                        <Flex direction="row" width="100%">
                            <Text
                                UNSAFE_className="filtersSectionLabel"
                                UNSAFE_style={{ paddingRight: 'calc(100% - 75px', paddingTop: '3px' }}
                                maxWidth={'90vw'}
                            >
                                FILTERS
                            </Text>
                        </Flex>
                    </Flex>
                </ActionButton>
            </Flex>
            <Flex direction="column" isHidden={!isOpen}>
                <Well margin={'0px'} maxWidth={800}>
                    <Flex direction="column">
                        <Flex direction="column">
                            <Flex direction="row" columnGap="10px" marginBottom={10}>
                                <Text UNSAFE_className="filtersSectionLabel">SEARCH</Text>
                            </Flex>
                            <Flex direction="row" columnGap="10px" marginBottom={10}>
                                <SearchField
                                    flexGrow={3}
                                    flexBasis={500}
                                    onChange={setQuery}
                                    onSubmit={handleQuerySubmit}
                                    onClear={() => {
                                        setQueryType('');
                                        handleQuerySubmit();
                                    }}
                                    value={query}
                                    // maxWidth="clamp(200px, calc(100vw - 2.5em), 550px)"
                                />
                            </Flex>
                            {!props?.restrictToCommander && !props?.restrictToAuthor && (
                                <RadioGroup
                                    orientation="horizontal"
                                    onChange={(val) => {
                                        setQueryType(val);
                                        handleQuerySubmit();
                                    }}
                                    value={queryType}
                                >
                                    <Radio value="commander">Commander</Radio>
                                    <Radio value="author">Author</Radio>
                                    <Radio value="title">Title</Radio>
                                </RadioGroup>
                            )}
                        </Flex>
                        <Flex direction="row" columnGap={50} marginTop={20} wrap>
                            {!props?.disableColorSelector && (
                                <Flex direction="column">
                                    <Text UNSAFE_className="filtersSectionLabel">POWER LEVEL RANGE</Text>
                                    <RangeSlider
                                        // UNSAFE_className="filtersSectionLabel"
                                        onChange={handleRangeSliderChange}
                                        marginTop={10}
                                        marginStart={0}
                                        marginBottom={10}
                                        labelPosition="side"
                                        label=" "
                                        minValue={1}
                                        maxValue={10}
                                        defaultValue={{ start: low, end: high }}
                                    />
                                </Flex>
                            )}
                            {!props?.disableColorSelector && (
                                <Flex direction="column" columnGap="10px">
                                    <Text UNSAFE_className="filtersSectionLabel">COLOR IDENTITY</Text>
                                    <ManaSelector
                                        handleColorFilterChange={handleColorFiltersChange}
                                        colorFilters={colorFilters}
                                    />
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Well>
            </Flex>
        </Flex>
    );
}
