import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector, getDomainPrefix } from '../../DynamoConnector';
import { setCommanderDetails } from './commandersSlice';
import { setAppRoute } from './appSlice';
import { setValue } from './searchSlice';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { toast } from 'react-toastify';

export const podsSlice = createSlice({
    name: 'pods',
    initialState: {
        currentPod: null,
        isCRUDing: false,
        decksAvailable: [],
    },
    reducers: {
        setIsCRUDing: (state, action) => {
            state.isCRUDing = action.payload;
        },
        setCurrentPod: (state, action) => {
            state.currentPod = action.payload;
            state.isCRUDing = false;
        },
        addToAvailableDecksList: (state, action) => {
            state.decksAvailable.push({
                name: `${action.payload?.authorId}'s decks`,
                id: `decks___${action.payload?.authorId}`,
                children: action?.payload?.list || [],
            });

            // console.log(JSON.stringify(state.decksAvailable, null, 4));
        },
    },
});

// Actions
export const doCreatePod = (name) => async (dispatch) => {
    dispatch(setIsCRUDing(true));
    const analytics = getAnalytics();

    try {
        const request = await fetch(`${getDomainPrefix()}/pods/create?name=${name}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json;charset=UTF-8',
            },
        });
        const response = await request.json();

        if (request.status !== 200) {
            throw response;
        }

        logEvent(analytics, 'pod_create_success', { name });

        const pod = {
            ...response?.pod,
            key: response?.pod?.id,
        };

        dispatch(setCurrentPod(pod));
        dispatch(setAppRoute({ route: `/pods/id/${pod?.id}` }));
    } catch (error) {
        logEvent(analytics, 'pod_create_fail', { name });
        toast(`${error.message}`);
    }
};

export const addAuthorToPod = (authorId, podId) => (dispatch) => {
    dispatch(setIsCRUDing(true));

    DynamoConnector.addAuthorToPod(
        authorId,
        podId,
        (pod) => {
            dispatch(setCurrentPod(pod));
        },
        (error) => {}
    );
};

export const addPlayerToPod = (playerName, podId) => async (dispatch) => {
    dispatch(setIsCRUDing(true));

    const analytics = getAnalytics();

    try {
        const request = await fetch(`${getDomainPrefix()}/pods/players/add?podId=${podId}&playerName=${playerName}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json;charset=UTF-8',
            },
        });
        const response = await request.json();

        if (request.status !== 200) {
            throw response;
        }

        dispatch(
            setCurrentPod({
                ...response?.pod,
                key: response?.pod?.id,
            })
        );
    } catch (error) {
        logEvent(analytics, 'pod_add_player_fail', { podId, playerName });
        toast(`${error.message}`);
    }
};

export const fetchDecksForAuthorInPod = (authorId) => (dispatch) => {
    if (!authorId) {
        return;
    }

    DynamoConnector.getShallowList(authorId, 'decks', 'byAuthorAndPowerRating', (list) => {
        if (list?.items && list?.items?.length > 0) {
            dispatch(addToAvailableDecksList({ authorId, list: list?.items || [] } || null));
        }
    });
};

export const fetchPod = (id) => async (dispatch) => {
    dispatch(setIsCRUDing(true));
    let fetchUri = `${getDomainPrefix()}/details?id=${id}&isPod=true`;

    const results = await (
        await fetch(fetchUri, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json;charset=UTF-8',
            },
        })
    ).json();

    dispatch(setCurrentPod(results || null));
};

export const { setIsCRUDing, setCurrentPod, addToAvailableDecksList } = podsSlice.actions;

// Selectors
// export const isCRUDing = (state) => state.pods.isCRUDing;
// export const currentPod = (state) => state.pods.currentPod;

export default podsSlice.reducer;
