import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, Flex } from '@adobe/react-spectrum';

import { BaseDeckPane } from './BaseDeckPane';
import { Funnel, FunnelChart, ResponsiveContainer } from 'recharts';
import { getGrades } from '../../../data/ScoreDisplayUtils';

export function SaltPane() {
    const deck = useSelector((state) => state.deck.deck);
    const cards = useSelector((state) => state.deck.cards);

    const CATEGORY_CONSTANTS = {
        annihilator: {
            color: '#d0d0d0',
            label: 'Annihilator',
            chartLabel: 'Annihilator',
        },
        boardwipes: {
            color: '#ffd47a',
            label: 'Board Wipes',
            chartLabel: 'Board Wipes',
        },
        cant: {
            color: '#ffffff',
            label: `You can't...`,
            chartLabel: `Uh... you can't do that`,
        },
        mld: {
            color: '#ff0000',
            label: 'MLD',
            chartLabel: 'Mass Land Destruction',
        },
        taxes: {
            color: '#6969c0',
            label: 'Taxes',
            chartLabel: 'Tax effects',
        },
        payTheOne: {
            color: '#ffdd00',
            label: 'Pay the...?',
            chartLabel: 'Do you pay the...?',
        },
        powergamer: {
            color: '#dd00ff',
            label: 'cEDH Staples',
            chartLabel: 'cEDH Staples',
            noChart: true,
        },
        sacrifice: {
            color: '#1a0000',
            label: 'Sacrifice',
            chartLabel: 'Sacrifice',
        },
        infiniteCombos: {
            color: '#00baff',
            label: 'Infinte Combos',
            chartLabel: 'Infinite combos',
        },
        reserved: {
            color: '#4d1600',
            label: 'Reserved List',
            chartLabel: 'Reserved List cards',
            noChart: true,
        },
        edhrec: {
            color: '#00ffa4',
            label: 'EDHRec Scores',
            chartLabel: 'EDHRec Scores',
            noChart: true,
        },
        locks: {
            color: '#0033ff',
            label: 'Locks',
            chartLabel: 'Locks',
        },
        theft: {
            color: '#6600ff',
            label: 'Theft',
            chartLabel: 'Theft',
        },
        chaos: {
            color: '#da0083',
            label: 'Chaos',
            chartLabel: 'Chaos effects',
        },
        groupSlug: {
            color: '#96000d',
            label: 'Group Slug',
            chartLabel: 'Group Slug',
        },
        extraTurns: {
            color: '#00e0ff',
            label: 'Extra Turns',
            chartLabel: 'Extra Turns',
        },
        extraCombats: {
            color: '#ffc500',
            label: 'Extra Combats',
            chartLabel: 'Extra Combats',
        },
        poison: {
            color: '#1c4b83',
            label: 'Poison',
            chartLabel: 'Poison',
        },
        wheels: {
            color: '#ff5409',
            label: 'Wheels',
            chartLabel: 'Wheels',
        },
    };

    const SUBCATEGORY_CONSTANTS = {
        cant: {
            cantActivate: {
                label: `Activate`,
                chartLabel: `Can't Activate`,
            },
            cantAttack: {
                label: `Attack`,
                chartLabel: `Can't Attack`,
            },
            cantCast: {
                label: `Cast`,
                chartLabel: `Can't Cast`,
            },
            cantDraw: {
                label: `Draw`,
                chartLabel: `Can't Draw`,
            },
            cantEven: {
                label: `Even`,
                chartLabel: `Can't Even`,
            },
            cantGainLife: {
                label: `Gain Life`,
                chartLabel: `Can't Gain Life`,
            },
            cantSearch: {
                label: `Search`,
                chartLabel: `Can't Search`,
            },
            cantTrigger: {
                label: `Trigger`,
                chartLabel: `Can't Trigger`,
            },
            cantUntap: {
                label: `Untap`,
                chartLabel: `Can't Untap`,
            },
        },
    };

    const getCategories = () => {
        let list = {};

        const categoryKeys = Object.keys(CATEGORY_CONSTANTS).sort((a, b) => {
            if (CATEGORY_CONSTANTS[a].label.toLowerCase() < CATEGORY_CONSTANTS[b].label.toLowerCase()) {
                return -1;
            }
            if (CATEGORY_CONSTANTS[a].label.toLowerCase() > CATEGORY_CONSTANTS[b].label.toLowerCase()) {
                return 1;
            }
            return 0;
        });

        categoryKeys.map((category) => {
            if (
                deck?.details?.salt?.scoring?.hasOwnProperty(category) ||
                category === 'cant' ||
                category === 'edhrec'
            ) {
                const item = deck?.details?.salt?.scoring?.[category];
                let items = Object.keys(item?.list || []).map((key) => {
                    const _item = item.list[key];

                    if (category?.toLowerCase()?.includes('combos') || category?.toLowerCase()?.includes('locks')) {
                        return {
                            ..._item,
                            name: _item?.name?.toString()?.replace(/,(?! )/g, `\n`),
                        };
                    } else {
                        return {
                            ..._item,
                            ...cards?.[key],
                        };
                    }
                });

                if (category === 'edhrec') {
                    items = Object.keys(cards)
                        ?.map((_id) => {
                            return { ...cards?.[_id], score: cards?.[_id]?.salt || 0 };
                        })
                        .filter((card) => {
                            return card.isFrontFace;
                        });
                }

                list[category] = {
                    label: `${CATEGORY_CONSTANTS[category]?.label}`,
                    id: category,
                    count: items?.length || 0,
                    list: items,
                    color: CATEGORY_CONSTANTS?.[category]?.color || `#FFFFFF`,
                    subCategories: [],
                    chartLabel: `${CATEGORY_CONSTANTS[category]?.chartLabel}`,
                };

                if (category === `cant`) {
                    Object.keys(SUBCATEGORY_CONSTANTS?.[category] || []).map((subCategory) => {
                        if (deck?.details?.salt?.scoring?.[subCategory]) {
                            const subItem = deck?.details?.salt?.scoring?.[subCategory];
                            const subItems = subItem?.list || [];

                            list[category].subCategories[subCategory] = {
                                label: `${SUBCATEGORY_CONSTANTS[category][subCategory]?.label}`,
                                id: `${subCategory}`,
                                count: subItems?.length || 0,
                                list: Object.keys(subItems || []).map((_key) => {
                                    const _card = subItems[_key];

                                    return {
                                        ..._card,
                                        ...cards?.[_key],
                                    };
                                }),
                                parentCategory: category,
                            };

                            list[category].count += Object.keys(subItems)?.length || 0;
                            list[category].list = list[category].list.concat(
                                list[category].subCategories[subCategory].list
                            );
                        }
                    });
                }
            }
        });

        if (list['cant']?.count < 1) {
            delete list['cant'];
        }

        return list;
    };

    const generateChartData = () => {
        let data = [];
        const maxRedShift = Math.ceil(100 - getGrades(deck)?.salt?.percentage);
        const color = `hsl(${maxRedShift} 100% 50%)`;

        Object.keys(getCategories()).map((category) => {
            if (!CATEGORY_CONSTANTS?.[category]?.noChart) {
                const item = getCategories()?.[category];

                let items = item?.list || [];

                if (items && items.length > 0) {
                    let subTotal = 0;
                    items?.forEach((item) => {
                        subTotal += parseFloat(item?.score || '0');
                    });

                    data.push({
                        value: subTotal,
                        label: item.chartLabel,
                        name: '',
                        fill: color,
                        // url(#Gradient1);
                    });
                }
            }
        });

        data = data.sort((a, b) => {
            return b.value - a.value;
        });

        const colorStepAmount = Math.ceil(getGrades(deck)?.salt?.percentage / data.length);

        let i = 0;
        data = data?.map((item) => {
            const mappedColor = `hsl(${maxRedShift + colorStepAmount * i} 100% 50%)`;

            i += 1;
            return {
                ...item,
                fill: mappedColor,
            };
        });

        return data;
    };

    const chartData = generateChartData();

    const saltPercentageScore = getGrades(deck)?.salt?.percentage;
    const fart = '😃🤨😒😫😢😭😤🤯😡🤬';
    let endGameEmojis = `😃 😃 😃`;
    if (saltPercentageScore > 90) {
        endGameEmojis = ` 🤬 🤬 🤬`;
    } else if (saltPercentageScore > 80) {
        endGameEmojis = ` 😡 😡 😡`;
    } else if (saltPercentageScore > 70) {
        endGameEmojis = ` 🤯 🤯 🤯`;
    } else if (saltPercentageScore > 60) {
        endGameEmojis = ` 😤 😤 😤`;
    } else if (saltPercentageScore > 50) {
        endGameEmojis = ` 😭 😭 😭`;
    } else if (saltPercentageScore > 40) {
        endGameEmojis = ` 😢 😢 😢`;
    } else if (saltPercentageScore > 30) {
        endGameEmojis = ` 🤨 🤨 🤨`;
    } else if (saltPercentageScore > 20) {
        endGameEmojis = ` 😒 😒 😒`;
    } else if (saltPercentageScore > 10) {
        endGameEmojis = ` 🥹 🥹 🥹`;
    }

    return (
        <BaseDeckPane title={'Salt'} fieldName="name" content={<div />} showScore={true} categories={getCategories()}>
            {/*<linearGradient id="Gradient1">*/}
            {/*    <stop id="stop1" offset="0%" />*/}
            {/*    <stop id="stop2" offset="50%" />*/}
            {/*    <stop id="stop3" offset="100%" />*/}
            {/*</linearGradient>*/}
            <Flex direction="column">
                <Flex
                    direction="column"
                    justifyContent="left"
                    UNSAFE_className="pieChartContainer"
                    marginTop="10px"
                    padding="10px"
                    width={'100%'}
                    // width="100vw"
                >
                    <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                        <p />
                        How are we going to lose friends today?
                    </span>
                    <Divider width="100%" marginTop="10px" size={'S'} />
                    <Flex direction="column" justifyContent="center" marginEnd="10px" marginStart={10}>
                        {chartData?.length > 0 && (
                            <div>
                                <Flex
                                    direction="row"
                                    justifyContent={'left'}
                                    height={100 + (chartData?.length || 1) * 50}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            maxWidth: '400px',
                                            paddingBottom: '250px',
                                            height: 200,
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                top: 0,
                                            }}
                                        >
                                            <span style={{ fontSize: '30px', paddingLeft: 7 }}>😃 😃 😃 😃</span>
                                            <Flex
                                                direction="row"
                                                justifyContent="left"
                                                UNSAFE_className="barChartContainer"
                                                marginTop="10px"
                                            >
                                                <ResponsiveContainer
                                                    width="100%"
                                                    height={(chartData?.length || 1) * 50}
                                                >
                                                    <FunnelChart
                                                        maxBarSize={5}
                                                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                                    >
                                                        <Funnel
                                                            dataKey="value"
                                                            stroke="#FF0000"
                                                            data={chartData}
                                                            isAnimationActive
                                                        ></Funnel>
                                                    </FunnelChart>
                                                </ResponsiveContainer>

                                                <div>
                                                    {chartData?.length > 0 &&
                                                        chartData.map((item) => {
                                                            const key = `funnelCell_${item.label}`;

                                                            return (
                                                                // <div className="previewGridCell" key={`GRID_PARENT_${key}`}>
                                                                <div
                                                                    style={{
                                                                        lineHeight: '50px',
                                                                    }}
                                                                    key={`GRID_LABEL_${key}`}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            verticalAlign: 'middle',
                                                                            lineHeight: 'normal',
                                                                        }}
                                                                    >
                                                                        {item.label}
                                                                    </span>
                                                                </div>
                                                                // </div>
                                                            );
                                                        })}
                                                </div>
                                            </Flex>
                                        </div>
                                    </div>
                                </Flex>
                                <div style={{ height: 10 }} />
                                <span style={{ fontSize: '30px', paddingTop: 20 }}>
                                    <img
                                        src="/resources/chef-kiss-header.png"
                                        width="35px"
                                        height="35px"
                                        style={{ position: 'relative', top: '7px' }}
                                    />
                                    {endGameEmojis}
                                </span>
                                <Divider width="100%" marginTop="10px" size={'S'} />
                                <span className="panelText" style={{ fontSize: '15px', fontWeight: 'bolder' }}>
                                    <p />
                                    {saltPercentageScore?.toFixed(3)}% chance of enraging your play group!
                                </span>
                            </div>
                        )}
                        <div style={{ height: 20 }} />
                    </Flex>
                </Flex>
            </Flex>
        </BaseDeckPane>
    );
}
