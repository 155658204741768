import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    Row,
    TableView,
    TableBody,
    TableHeader,
    Flex,
    MenuTrigger,
    ActionButton,
    Menu,
    Item,
} from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './ResultsListing.css';
import '../filterpanel/mana-cost.css';
// import { Preview } from '../preview/Preview';
import { fetchPreviewDeck } from '../../data/redux/slices/previewSlice';
import { setAppRoute, setRouteChangeRefreshPending } from '../../data/redux/slices/appSlice';
import { getAnalytics, logEvent } from 'firebase/analytics';
import isMobile from 'ismobilejs';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import ChevronUp from '@spectrum-icons/workflow/ChevronUp';
import { fetchDeck } from '../../data/redux/slices/deckSlice';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
let isScrolling = false;

const scrollListItemIntoView = async (index, prefix, mobile) => {
    if (isScrolling) {
        return;
    }

    try {
        if (index && index > 0) {
            let test = document.getElementById(`${prefix}0`);

            if (test) {
                let increment = 1;

                // if (document.getElementById(`${prefix}${increment + 3}`)) {
                //     increment = 3;
                // }

                // if (!mobile) {
                //     if (document.getElementById(`${prefix}${increment + 7}`)) {
                //         increment = 7;
                //     }
                //
                //     if (document.getElementById(`${prefix}${increment + 10}`)) {
                //         increment = 10;
                //     }
                // }

                let actual = document.getElementById(`${prefix}${index}`);

                if (!actual) {
                    // console.log(
                    //     `attempting to scroll to: ${prefix}${index}; increment by ${increment}; mobile => ${mobile}`
                    // );

                    isScrolling = true;
                    let i = 0;
                    let redoCount = 0;

                    do {
                        test = document.getElementById(`${prefix}${i}`);
                        if (test) {
                            document.getElementById(`${prefix}${i}`)?.scrollIntoView();
                            // document.getElementById(`${prefix}${i}`)?.scrollTo(0);
                            i += increment;

                            actual = document.getElementById(`${prefix}${index}`);
                            redoCount = 0;
                        } else {
                            redoCount += 1;
                            if (redoCount > 30) {
                                throw new Error('unable to find selected id!');
                            }

                            await delay(30);
                        }
                    } while (!actual);
                    document.getElementById(`${prefix}${index - 1}`)?.scrollIntoView();
                    isScrolling = false;
                }
                document.getElementById(`${prefix}${index - 1}`)?.scrollIntoView();
            }
        }
    } catch (error) {
        console.log(`cant scroll to item...`, error);
        isScrolling = false;
    }
};

export function ResultsListing(props) {
    const dispatch = useDispatch();
    const sortBy = props?.sortBy;
    const selectedListIndex = props?.selectedListIndex;
    const selectedListIndexPrefix = props?.selectedListIndexPrefix;

    const routeChangeRefreshPending = useSelector((state) => state.app.routeChangeRefreshPending);
    const mobile = isMobile(window.navigator).phone;

    document.getElementById('body').className = props?.previewIsShowing ? 'bodyNoScroll' : 'body';

    const handleSortChange = (sortIndex) => {
        try {
            logEvent(getAnalytics(), `setSort_${sortIndex.toUpperCase()}`);
        } catch (error) {}

        props?.handleSortChange(sortIndex);
    };

    const handleSortDirectionChange = () => {
        const direction =
            props.sortDirection === SORT_MAP.DIRECTION.DESC ? SORT_MAP.DIRECTION.ASC : SORT_MAP.DIRECTION.DESC;

        try {
            logEvent(getAnalytics(), `setSort_direction_${direction.toUpperCase()}`);
        } catch (error) {}

        props?.handleSortDirectionChange && props?.handleSortDirectionChange(direction);
    };

    const initialSortDescriptor = {
        column: 'saltRating',
        direction: 'descending',
    };

    if (routeChangeRefreshPending === props?.routePath) {
        if (selectedListIndex && selectedListIndex > 0) {
            scrollListItemIntoView(selectedListIndex, selectedListIndexPrefix, mobile);
            dispatch(setRouteChangeRefreshPending(null));
        }
    }

    const fart = useRef(null);
    const container = useRef(null);

    const getTableHeight = () => {
        const zed = fart?.current?.UNSAFE_getDOMNode();
        return `${window.innerHeight - zed?.getBoundingClientRect()?.top - 50}px`;
    };

    const getTableMinHeight = () => {
        const zed = fart?.current?.UNSAFE_getDOMNode();

        if (!zed) {
            return `${window.innerHeight}px`;
        }

        if (zed.getBoundingClientRect().top < 120) {
            return `${window.innerHeight - 120}px`;
        }

        return getTableHeight();
    };

    // const tableHeight = `100%`;
    const [tableHeight, setTableHeight] = useState(getTableHeight());
    const [minTableHeight, setMinTableHeight] = useState(getTableMinHeight());
    const containerDomNode = fart?.current?.UNSAFE_getDOMNode();

    const handler = () => {
        setTableHeight(getTableHeight());
        setMinTableHeight(getTableMinHeight());
        if (containerDomNode?.getBoundingClientRect()?.top < 0) {
            window.scrollTo(containerDomNode.scrollHeight - 50);
        }
    };

    useEffect(() => {
        if (minTableHeight !== getTableMinHeight() || minTableHeight === `${window.innerHeight}px`) {
            handler();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handler, { passive: true });

        return () => {
            window.removeEventListener('scroll', handler);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handler, { passive: true });

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    const maxTableHeight = `${window.innerHeight - 120}px`;

    const handleSelectionChange = (evn) => {
        try {
            const currentKey = evn?.currentKey || props?.selectedListKey;
            const item = props?.listItems?.filter((value) => {
                return value?.id === currentKey;
            })?.[0];

            // if (props?.allowDeckPreview) {
            //     if (props?.disableNavChanges) {
            //         // dispatch(fetchPreviewDeck(currentKey));
            //         dispatch(fetchDeck(currentKey))
            //     } else {
            dispatch(setAppRoute({ route: `/details/deck/${currentKey}` }));
            // }
            // }

            props?.handleIndexSelection && props.handleIndexSelection(item?.index);
            props?.handleSelectionChange && props.handleSelectionChange(currentKey || null);
        } catch (error) {}
    };

    return (
        <Flex direction="column" alignItems="center" gap="size-100" ref={container}>
            <Flex direction="row" marginEnd={20} marginStart={20}>
                <div style={{ width: '1px', height: maxTableHeight }} />
                <Flex direction="column">
                    <Flex
                        direction="row"
                        alignSelf="flex-end"
                        marginEnd="10px"
                        position="relative"
                        UNSAFE_style={{ top: '2.0em', zIndex: 100 }}
                    >
                        <MenuTrigger>
                            <ActionButton UNSAFE_style={{ height: '25px' }}>{sortBy.label}</ActionButton>
                            <Menu onAction={(key) => handleSortChange(key)}>
                                <Item key={SORT_MAP.POWERLEVEL.enum}>{SORT_MAP.POWERLEVEL.label}</Item>
                                <Item key={SORT_MAP.SALT.enum}>{SORT_MAP.SALT.label}</Item>
                                {props?.includeSortByCount && (
                                    <Item key={SORT_MAP.COUNT.enum}>{SORT_MAP.COUNT.label}</Item>
                                )}
                            </Menu>
                        </MenuTrigger>
                        <ActionButton UNSAFE_style={{ height: '25px' }} onPress={handleSortDirectionChange}>
                            {props?.sortDirection === SORT_MAP.DIRECTION.DESC ? <ChevronDown /> : <ChevronUp />}
                        </ActionButton>
                    </Flex>
                    <TableView
                        overflowMode="wrap"
                        aria-label="All time salt index"
                        density="compact"
                        selectionMode="single"
                        selectionStyle="highlight"
                        onSelectionChange={handleSelectionChange}
                        selectedKeys={[props?.selectedListKey]}
                        sortDescriptor={initialSortDescriptor}
                        UNSAFE_style={{
                            height: tableHeight,
                            minHeight: minTableHeight,
                            maxHeight: maxTableHeight,
                            width: 'calc(100dvw - 20px)',
                        }}
                        ref={fart}
                        id="resultsTable"
                    >
                        <TableHeader columns={props?.columns}>
                            {(column) => (
                                <Column
                                    key={column?.uid}
                                    align={column?.uid === 'authorAvatarUrl' ? 'start' : 'start'}
                                    maxWidth={column?.maxWidth}
                                    width={column?.width}
                                    minWidth={column?.minWidth}
                                >
                                    {props?.columnRenderer(column)}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody
                            items={props?.listItems}
                            loadingState={props?.loadingState}
                            onLoadMore={props?.handleLoadMore}
                        >
                            {(item) => <Row>{(columnKey) => props?.cellRenderer(item, columnKey)}</Row>}
                        </TableBody>
                    </TableView>
                </Flex>
            </Flex>
            {/*{props?.previewIsShowing ? (*/}
            {/*    <div>*/}
            {/*        <div className="modal" />*/}
            {/*        <Preview disableNavChanges={props?.disableNavChanges || false} />*/}
            {/*    </div>*/}
            {/*) : (*/}
            {/*    <div style={{ height: '0px' }} />*/}
            {/*)}*/}
        </Flex>
    );
}
