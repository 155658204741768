import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Cell, Flex } from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './Authors.css';
import {
    authorsFetchFiltered,
    fetchAuthorsItems,
    setAuthorSortDirection,
    setAuthorsSortBy,
} from '../../data/redux/slices/authorsSlice';
import { getGrades } from '../../data/ScoreDisplayUtils';
import { Helmet } from 'react-helmet';
import { ResultsListing } from '../resultsListing/ResultsListing';
import { setAppRoute } from '../../data/redux/slices/appSlice';
import { FilterPanel } from '../filterpanel/FilterPanel';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';

export function Authors() {
    const dispatch = useDispatch();
    let listItems = useSelector((state) => state?.authors?.listItems);
    listItems = (listItems || []).filter((item) => {
        return item && item?.id;
    });
    const nextCursor = useSelector((state) => state?.authors?.nextCursor);
    const isFetching = useSelector((state) => state?.authors?.isFetching);
    const loadingState = useSelector((state) => state?.authors?.loadingState);
    const searchFilters = useSelector((state) => state?.authors?.filters);
    const details = useSelector((state) => state.authors?.details);
    const sortBy = useSelector((state) => state?.authors?.sortBy);
    const previewIsShowing = useSelector((state) => state.preview.isShowing);

    const handleSortChange = (sortIndex) => {
        dispatch(setAuthorsSortBy(SORT_MAP[sortIndex], searchFilters));
        dispatch(fetchAuthorsItems(null, true, SORT_MAP[sortIndex], sortDirection));
    };

    const sortDirection = useSelector((state) => state.authors.sortDirection);
    const handleSortDirectionChange = (direction) => {
        dispatch(setAuthorSortDirection(direction));
        dispatch(fetchAuthorsItems(null, true, sortBy, direction, searchFilters));
    };

    const handleFiltersChange = (filters) => {
        dispatch(authorsFetchFiltered(filters, sortBy));
    };

    const getCellRenderer = (item, columnKey) => {
        switch (columnKey) {
            case 'score':
                let score = item.hasOwnProperty('score') ? item.score : item[sortBy?.field];

                if (sortBy.field === SORT_MAP.COUNT.field) {
                    score = item['powerLevelRating'];
                }

                const grade = getGrades(item);
                const prop = sortBy?.enum.toLowerCase();
                let floatValue = parseFloat(/[0-9]*\.[0-9]/.exec(score.toString())?.[0] || '0');
                if (!floatValue.toString().includes(`.`)) {
                    floatValue = `${floatValue}.0`;
                }

                return (
                    <Cell>
                        <Flex direction={'column'} justifyContent={'space-around'}>
                            <div className="SaltCell" style={{ color: grade?.[prop]?.color, float: 'right' }}>
                                {floatValue}
                            </div>
                            {!details && (
                                <span className="SaltCellSubData" style={{ marginTop: 5 }}>
                                    {item?.count} DECK{item?.count > 1 ? 'S' : ''}
                                </span>
                            )}
                        </Flex>
                    </Cell>
                );
                break;
            case 'authors':
                return (
                    <Cell>
                        <div className="TextCell">{item?.metadata?.username || item?.id}</div>
                    </Cell>
                );

                break;
            case 'authorAvatarUrl':
                const avatarUrl = item?.metadata?.profileImageUrl || `/resources/blank-user-avatar.png`;

                return (
                    <Cell>
                        <div className="AvatarCell">
                            <img src={avatarUrl} height="50px" alt={item.metadata?.username} className="AvatarCell" />
                        </div>
                    </Cell>
                );
                break;
            default:
                return (
                    <Cell>
                        <div className="TextCell">{item[columnKey]}</div>
                    </Cell>
                );
        }
    };

    const getColumnRenderer = (item) => {
        let content;

        switch (item.uid) {
            case 'saltRating':
                content = <div />;
                break;
            case 'authorAvatarUrl':
                content = <div />;
                break;
            default:
                content = item.name;
        }

        return <div style={{ minWidth: '83px' }}>{content}</div>;
    };

    const handleLeaderboardSelectionChange = (id) => {
        // try {
        //     if (id) {
        dispatch(setAppRoute({ route: `/authors/id/${id}` }));
        //     }
        // } catch (error) {}
    };

    const handleLoadMore = () => {
        if (!isFetching && nextCursor !== null) {
            dispatch(fetchAuthorsItems(nextCursor, false, sortBy, sortDirection));
        }
    };

    let columns = [
        { name: 'sdfsd', uid: 'authorAvatarUrl', maxWidth: 65, minWidth: 65, width: 65 },
        { name: 'Author', uid: 'authors' },
        { name: '', uid: 'score', width: 90 },
    ];

    const helmetName = `Authors`;
    const helmetCanonical = `https://www.commandersalt.com/authors`;
    const helmetDescription = `The saltiest MtG deck builders on the planet!`;
    const helmetTitle = `Commandersalt.com - The saltiest MtG deck builders on the planet!`;

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            <FilterPanel
                onFiltersChange={handleFiltersChange}
                filters={searchFilters}
                disableColorSelector={true}
                restrictToAuthor={true}
            />
            <ResultsListing
                includeSortByCount={true}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSortChange={handleSortChange}
                handleSortDirectionChange={handleSortDirectionChange}
                disableNavChanges={true}
                allowDeckPreview={false}
                handleSelectionChange={handleLeaderboardSelectionChange}
                columns={columns}
                columnRenderer={getColumnRenderer}
                listItems={listItems}
                cellRenderer={getCellRenderer}
                previewIsShowing={previewIsShowing}
                handleLoadMore={handleLoadMore}
                loadingState={loadingState}
            />
        </Flex>
    );
}
