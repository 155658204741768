import React, { useState } from 'react';
import { Grid, Flex, TableView, TableHeader, TableBody, Row, Column, repeat } from '@adobe/react-spectrum';
import { resultsCellRenderers } from '../../resultsListing/renderers/ResultsCellRenderers';
import { Lightbox } from 'react-modal-image';
import isMobile from 'ismobilejs';

export function StaplesPane(props) {
    const mobile = isMobile(window.navigator).phone;

    const [imgPreviewUri, setImgPreviewUri] = useState(null);

    const permanentTypes = ['planeswalker', 'creature', 'sorcery', 'instant', 'artifact', 'enchantment', 'land'];
    const typesHash = {};

    const foundCards = [];

    permanentTypes.forEach((type) => {
        const blob = { type, list: [] };
        props?.metaBlob?.cards?.forEach((id) => {
            const cardObject = props?.metaBlob?.staplesMetadata?.[id];

            if (cardObject?.baseTypes?.includes(type) && !foundCards.includes(id)) {
                blob.list.push(cardObject);
                foundCards.push(id);
            }
        });

        if (blob.list.length > 0) {
            typesHash[type] = blob;
        }
    });

    const getColumnRenderer = (item) => {
        let content;

        return <div style={{ fontSize: '0.7rem' }}>{item.name}</div>;
    };

    const renderBlob = (type) => {
        const columns = [
            // { name: '#', uid: 'position', maxWidth: 60, minWidth: 60, width: 60 },
            { name: 'Name', uid: 'names', maxWidth: 500, minWidth: 200 },
            { name: 'Count', uid: 'count', maxWidth: 70, minWidth: 70, width: 70 },
            { name: 'Meta %', uid: 'metaShare', maxWidth: 80, minWidth: 80, width: 80 },
        ];

        let count = 0;
        const rows = (typesHash?.[type]?.list || []).map((item) => {
            return {
                ...item,
                metaShare: parseFloat(item?.metaShare || 0).toFixed(2),
            };
        });

        return (
            <div id={`__staplesByType__${type}`} style={{ margin: 20 }}>
                <Flex direction={'column'} maxWidth={900} marginStart={10} marginEnd={20}>
                    <div className={'commanderTiersTileStatisticsHeader'}>
                        {type?.substring(0, 1)?.toUpperCase()}
                        {type?.substring(1)}
                    </div>
                    <TableView overflowMode="wrap" height={700} density="compact">
                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column
                                    key={column.uid}
                                    align={column.uid === 'date' ? 'end' : 'start'}
                                    maxWidth={column?.maxWidth}
                                    width={column?.width}
                                    minWidth={column?.minWidth}
                                >
                                    {getColumnRenderer(column)}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={rows}>
                            {(item) => (
                                <Row>
                                    {(columnKey) => resultsCellRenderers.CARD_LIST(item, columnKey, setImgPreviewUri)}
                                </Row>
                            )}
                        </TableBody>
                    </TableView>
                </Flex>
            </div>
        );
    };

    return (
        <div>
            {imgPreviewUri && (
                <div onClick={() => setImgPreviewUri(null)}>
                    <Lightbox
                        medium={''}
                        large={imgPreviewUri}
                        hideDownload={true}
                        hideZoom={true}
                        onClose={() => setImgPreviewUri(null)}
                    />
                </div>
            )}

            <Grid columns={repeat('auto-fit', mobile ? 400 : 650)} gap={50} justifyContent="center">
                {permanentTypes &&
                    permanentTypes.map((type) => {
                        return <div>{renderBlob(type)}</div>;
                    })}
            </Grid>
        </div>
    );
}
