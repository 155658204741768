import React from 'react';
import { Flex, ActionButton, Divider, Checkbox, Text } from '@adobe/react-spectrum';
import './mana-cost.css';

export function ManaSelector(props) {
    const wSelected = props?.colorFilters?.includes(`w`);
    const uSelected = props?.colorFilters?.includes(`u`);
    const bSelected = props?.colorFilters?.includes(`b`);
    const rSelected = props?.colorFilters?.includes(`r`);
    const gSelected = props?.colorFilters?.includes(`g`);
    const cSelected = props?.colorFilters?.includes(`c`);

    const handleChange = (evn) => {
        const { type, value } = evn;
        let tempState = { wSelected, uSelected, bSelected, rSelected, gSelected, cSelected };

        if (type === 'c') {
            if (value) {
                tempState = {
                    ...tempState,
                    wSelected: false,
                    uSelected: false,
                    bSelected: false,
                    rSelected: false,
                    gSelected: false,
                };
            }
        } else {
            tempState = {
                ...tempState,
                cSelected: false,
            };
        }

        tempState[`${type}Selected`] = value;

        const colorIdentity = `${tempState.wSelected ? 'w' : ''}${tempState.uSelected ? 'u' : ''}${
            tempState.bSelected ? 'b' : ''
        }${tempState.rSelected ? 'r' : ''}${tempState.gSelected ? 'g' : ''}${tempState.cSelected ? 'c' : ''}`;

        props?.handleColorFilterChange && props.handleColorFilterChange({ colors: colorIdentity });
    };

    return (
        <Flex direction="column">
            <div style={{ height: '7px' }} />
            <Flex direction="row" alignItems="center" columnGap="0px">
                <ActionButton
                    isQuiet={true}
                    onPress={() => {
                        handleChange({ type: 'w', value: wSelected ? false : true });
                    }}
                    isDisabled={props?.disabledColors?.includes('W')}
                >
                    <span className="mana medium sw" style={{ opacity: wSelected ? '1' : '0.2' }}></span>
                </ActionButton>
                <ActionButton
                    isQuiet={true}
                    onPress={() => {
                        handleChange({ type: 'u', value: uSelected ? false : true });
                    }}
                    isDisabled={props?.disabledColors?.includes('U')}
                >
                    <span className="mana medium su" style={{ opacity: uSelected ? '1' : '0.2' }}></span>
                </ActionButton>
                <ActionButton
                    isQuiet={true}
                    onPress={() => {
                        handleChange({ type: 'b', value: bSelected ? false : true });
                    }}
                    isDisabled={props?.disabledColors?.includes('B')}
                >
                    <span className="mana medium sb" style={{ opacity: bSelected ? '1' : '0.2' }}></span>
                </ActionButton>
                <ActionButton
                    isQuiet={true}
                    onPress={() => {
                        handleChange({ type: 'r', value: rSelected ? false : true });
                    }}
                    isDisabled={props?.disabledColors?.includes('R')}
                >
                    <span className="mana medium sr" style={{ opacity: rSelected ? '1' : '0.2' }}></span>
                </ActionButton>
                <ActionButton
                    isQuiet={true}
                    onPress={() => {
                        handleChange({ type: 'g', value: gSelected ? false : true });
                    }}
                    isDisabled={props?.disabledColors?.includes('G')}
                >
                    <span className="mana medium sg" style={{ opacity: gSelected ? '1' : '0.2' }}></span>
                </ActionButton>
                <Divider orientation="vertical" size="S" />
                <ActionButton
                    isQuiet={true}
                    onPress={() => {
                        handleChange({ type: 'c', value: cSelected ? false : true });
                    }}
                >
                    <span className="mana medium sc" style={{ opacity: cSelected ? '1' : '0.2' }}></span>
                </ActionButton>
            </Flex>
        </Flex>
    );
}
