import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActionButton,
    Button,
    ButtonGroup,
    Cell,
    Content,
    Dialog,
    DialogContainer,
    DialogTrigger,
    Divider,
    Flex,
    Heading,
    Text,
    View,
} from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './Authors.css';
import { getGrades } from '../../data/ScoreDisplayUtils';
import { Helmet } from 'react-helmet';
import { ResultsListing } from '../resultsListing/ResultsListing';
import { resultsColumns } from '../resultsListing/columns/ResultsColumns';
import { resultsCellRenderers } from '../resultsListing/renderers/ResultsCellRenderers';
import { resultsColumnRenderers } from '../resultsListing/renderers/ResultsColumnRenderers';
import User from '@spectrum-icons/workflow/User';
import { getAnalytics, logEvent } from 'firebase/analytics';
import isMobile from 'ismobilejs';
import {
    fetchAuthorDetailsItems,
    setAuthorDetailsSortBy,
    setAuthorDetailsSortDirection,
} from '../../data/redux/slices/authorDetailsSlice';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { doRefresh } from '../../data/redux/slices/importSlice';
import { batchRefreshAuthor } from '../../data/redux/slices/authorDetailsSlice';
import Refresh from '@spectrum-icons/workflow/Refresh';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import { SubmitForm } from '../submitform/SubmitForm';
import { ImportStatusBar } from '../importStatusBar/ImportStatusBar';
import { getDomainPrefix } from '../../data/DynamoConnector';
import { DownloadIcon } from 'react-modal-image/lib/icons';
import { PrintDialog } from '../widgets/printDialog/PrintDialog';

export function AuthorDetails() {
    const dispatch = useDispatch();
    let listItems = useSelector((state) => state?.authorDetails?.listItems);
    listItems = (listItems || []).filter((item) => {
        return item && item?.id;
    });
    const nextCursor = useSelector((state) => state?.authorDetails?.nextCursor);
    const isFetching = useSelector((state) => state?.authorDetails?.isFetching);
    const loadingState = useSelector((state) => state?.authorDetails?.loadingState);
    const searchFilters = useSelector((state) => state?.authorDetails?.filters);
    const details = useSelector((state) => state?.authorDetails?.details);
    const sortBy = useSelector((state) => state?.authorDetails?.sortBy);
    const previewIsShowing = useSelector((state) => state.preview.isShowing);
    const mobile = isMobile(window.navigator).phone;
    const isRefreshing = useSelector((state) => state.authorDetails.isRefreshing);
    const isImporting = useSelector((state) => state.import.isImporting);

    const handleSortChange = (sortIndex) => {
        dispatch(setAuthorDetailsSortBy(SORT_MAP[sortIndex], searchFilters));
        dispatch(fetchAuthorDetailsItems(null, true, SORT_MAP[sortIndex], sortDirection));
    };

    const sortDirection = useSelector((state) => state.authorDetails.sortDirection);
    const handleSortDirectionChange = (direction) => {
        dispatch(setAuthorDetailsSortDirection(direction));
        dispatch(fetchAuthorDetailsItems(null, true, sortBy, direction, searchFilters));
    };

    const grades = details ? getGrades(details) : null;

    const handleLoadMore = () => {
        if (!isFetching && nextCursor !== null) {
            dispatch(fetchAuthorDetailsItems(nextCursor, false, sortBy, sortDirection));
        }
    };

    const handleAuthorLinkPress = () => {
        logEvent(getAnalytics(), 'authors_open_author', { url: details?.metadata?.url });
        window.open(details?.metadata?.url, `_blank`);
    };

    const helmetName = `Authors > ${details?.id}`;
    const helmetCanonical = `https://www.commandersalt.com/authors/id${details?.id}`;
    const helmetDescription = `MtG Commander decks by ${details?.id}`;
    const helmetTitle = `Commandersalt.com - MtG Commander decks by ${details?.id}`;

    const displayProgressBar = isImporting || isRefreshing;
    const progressDialogHeader = isImporting ? 'Importing deck...' : 'Refreshing - DOES NOT REIMPORT';

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            {displayProgressBar && (
                <DialogContainer type={'modal'}>
                    <Dialog width={'calc(100vw - 40px)'} maxWidth={800}>
                        <Heading>{progressDialogHeader}</Heading>
                        {/*<Header>Connection status: Connected</Header>*/}
                        <Divider />
                        <Content>
                            {/*<div style={{ maxWidth: 800, width: '100%' }}>*/}
                            {/*<Text>Start speed test?</Text>*/}
                            <Flex direction={'row'} UNSAFE_style={{ paddingTop: 20 }}>
                                {<ImportStatusBar />}
                            </Flex>
                        </Content>
                        {/*<ButtonGroup>*/}
                        {/*    <Button variant="secondary" onPress={close}>*/}
                        {/*        Cancel*/}
                        {/*    </Button>*/}
                        {/*</ButtonGroup>*/}
                    </Dialog>
                </DialogContainer>
            )}
            <BreadCrumbs
                content={
                    <Flex direction="row" gap="size-100" justifyContent="right">
                        <View
                            borderWidth="thin"
                            borderColor="light"
                            borderRadius="medium"
                            padding="size-50"
                            backgroundColor="gray-75"
                            minHeight="0px"
                        >
                            <Flex direction="row" gap="size-100">
                                <ActionButton
                                    aria-label="View author profile"
                                    // isDisabled={authorUrlButtonDisabled || !deck}
                                    onPress={handleAuthorLinkPress}
                                    isQuiet="true"
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                >
                                    <User />
                                </ActionButton>
                            </Flex>
                        </View>
                        <View
                            borderWidth="thin"
                            borderColor="light"
                            borderRadius="medium"
                            padding="size-50"
                            backgroundColor="gray-75"
                            minHeight="0px"
                        >
                            <Flex direction="row" gap="size-100">
                                {!mobile && (
                                    <div
                                        style={details ? { cursor: 'pointer' } : {}}
                                        onClick={() => {
                                            if (!isImporting && !isRefreshing && details) {
                                                logEvent(getAnalytics(), 'author_refresh');
                                                dispatch(doRefresh(details.id));
                                            }
                                        }}
                                    >
                                        <Text
                                            UNSAFE_className="reportCardSectionTitles"
                                            marginStart={10}
                                            top={7}
                                            position={'relative'}
                                        >
                                            REFRESH
                                        </Text>
                                    </div>
                                )}
                                <ActionButton
                                    type="reset"
                                    alignSelf="flex-end"
                                    isDisabled={isImporting || isRefreshing || !details}
                                    isQuiet="true"
                                    aria-label="Refresh author"
                                    UNSAFE_style={{ cursor: 'pointer' }}
                                    onPress={() => {
                                        logEvent(getAnalytics(), 'author_refresh', {
                                            url: details.id,
                                        });
                                        dispatch(batchRefreshAuthor(listItems));
                                    }}
                                >
                                    <Refresh />
                                </ActionButton>
                            </Flex>
                        </View>
                        <View
                            borderWidth="thin"
                            borderColor="light"
                            borderRadius="medium"
                            padding="size-50"
                            backgroundColor="red-400"
                            minHeight="0px"
                        >
                            <Flex direction="row" gap="size-100">
                                <DialogTrigger type={'modal'}>
                                    <ActionButton
                                        type="reset"
                                        alignSelf="flex-end"
                                        isDisabled={isImporting || isRefreshing}
                                        isQuiet="true"
                                        UNSAFE_style={{ cursor: 'pointer' }}
                                        aria-label="Import new deck"
                                        // onPress={handleAddNewDeckPress}
                                    >
                                        <AddCircle />
                                    </ActionButton>
                                    {(close) => (
                                        <Dialog width={'calc(100vw - 40px)'} maxWidth={800}>
                                            <Heading>Import Deck</Heading>
                                            {/*<Header>Connection status: Connected</Header>*/}
                                            <Divider />
                                            <Content>
                                                {/*<div style={{ maxWidth: 800, width: '100%' }}>*/}
                                                {/*<Text>Start speed test?</Text>*/}
                                                <Flex direction={'row'}>
                                                    {
                                                        // isImporting ? <ImportStatusBar /> : <SubmitForm /> // initialListUrl={param} />
                                                        <SubmitForm onStartCallback={close} />
                                                    }
                                                </Flex>
                                            </Content>
                                            <ButtonGroup>
                                                <Button variant="secondary" onPress={close}>
                                                    Cancel
                                                </Button>
                                            </ButtonGroup>
                                        </Dialog>
                                    )}
                                </DialogTrigger>
                            </Flex>
                        </View>
                    </Flex>
                }
            />
            <Flex margin={10} direction={'row'} wrap justifyContent={'space-around'}>
                {/*<div className="leftPanelPartner">*/}
                <Flex direction="row" justifyContent="center">
                    {details?.metadata?.profileImageUrl && (
                        <img src={details?.metadata?.profileImageUrl} height={mobile ? 250 : 400} />
                    )}
                </Flex>
                {/*</div>*/}
                <div className="rightPanel">
                    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <View UNSAFE_className="authorNamesTextContainer" padding="10px">
                            <Flex direction="row" justifyContent="space-between">
                                <Text UNSAFE_className="authorNamesText">{details?.username || details?.id}</Text>
                            </Flex>
                        </View>
                        <View
                            width="100%"
                            maxWidth="size-6000"
                            alignSelf="center"
                            UNSAFE_className="commandersMetaBreakdownHeader"
                        >
                            <Text UNSAFE_className="commanderNamesText">
                                {`${details?.count > 1 ? 'Average scores' : 'Scores'} for ${details?.count} deck${
                                    details?.count > 1 ? 's' : ''
                                }`}
                            </Text>
                        </View>
                        <Flex direction="row" columnGap={10} justifyContent="center">
                            <Flex direction="column" alignItems="end">
                                <div className="metaFieldLabel">Power Level:</div>
                                <div className="metaFieldLabel">Saltiness:</div>
                                <div className="metaFieldLabel">Combo:</div>
                                <div className="metaFieldLabel">Synergy:</div>
                            </Flex>
                            {details !== null && (
                                <Flex direction="column">
                                    <div className="metaFieldLabel" style={{ color: grades?.['powerlevel']?.color }}>
                                        {parseFloat(details?.powerLevelRating)?.toFixed(1) || 0}
                                    </div>
                                    <div className="metaFieldLabel" style={{ color: grades?.['salt']?.color }}>
                                        {parseFloat(details?.saltRating).toFixed(1) || 0}
                                    </div>
                                    <div className="metaFieldLabel" style={{ color: grades?.['combo']?.color }}>
                                        {parseFloat(details?.comboRating).toFixed(1) || 0}
                                    </div>
                                    <div className="metaFieldLabel" style={{ color: grades?.['synergy']?.color }}>
                                        {parseFloat(details?.synergyRating).toFixed(1) || 0}
                                    </div>
                                </Flex>
                            )}
                        </Flex>
                    </div>
                </div>
            </Flex>
            <PrintDialog authorId={details?.id} pageType={'author'} name={details?.name} />
            <ResultsListing
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSortChange={handleSortChange}
                handleSortDirectionChange={handleSortDirectionChange}
                disableNavChanges={true}
                allowDeckPreview={true}
                handleSelectionChange={null}
                columns={resultsColumns.LEADERBOARD()}
                columnRenderer={resultsColumnRenderers.LEADERBOARD}
                listItems={listItems}
                cellRenderer={resultsCellRenderers.LEADERBOARD}
                previewIsShowing={previewIsShowing}
                handleLoadMore={handleLoadMore}
                loadingState={loadingState}
            />
        </Flex>
    );
}
