import './App.css';
import { Flex } from '@adobe/react-spectrum';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Outlet, ScrollRestoration } from 'react-router-dom';
import { HeaderBar } from './components/headerBar/HeaderBar';
import { FooterBar } from './components/footerBar/FooterBar';
import { handleUpdatedAppRoute } from './data/redux/slices/appSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function AppBody() {
    const navigate = useNavigate();
    const route = useSelector((state) => state.app.route);
    const dispatch = useDispatch();

    const prevRoute = useRef(route?.path || '/');

    useEffect(() => {
        let currentRoute = route?.path;

        if (prevRoute.current !== currentRoute) {
            const uri = window.location.pathname;

            if (!currentRoute && uri !== prevRoute.current) {
                currentRoute = uri;
            }

            prevRoute.current = currentRoute || '/';

            navigate(currentRoute);
            dispatch(handleUpdatedAppRoute({ route: currentRoute, firstRouteHandled: false }));
        }
    }, [route]);

    window.addEventListener('popstate', (event) => {
        if (window.location.href !== route.path) {
            const uri = window.location.pathname;

            if (prevRoute.current !== uri) {
                prevRoute.current = uri;
                dispatch(handleUpdatedAppRoute({ route: uri }));
            }
        }
    });

    return (
        // <ScrollRestoration>
        <div style={{ maxWidth: '100vw', overflowX: 'clip' }}>
            <div>
                <ToastContainer />
                <Flex id="AppContainer" direction="column">
                    <HeaderBar />
                    <Flex id="ContentContainer" direction="row" justifyContent="center">
                        <Flex
                            id="CenterContentContainer"
                            direction="column"
                            marginBottom="35px"
                            maxWidth={2000}
                            width={'100vw'}
                            // width={2000}
                        >
                            <Outlet />
                        </Flex>
                    </Flex>
                    <FooterBar />
                </Flex>
            </div>
        </div>
        // </ScrollRestoration>
    );
}
