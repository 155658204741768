import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Text, View } from '@adobe/react-spectrum';
import { SORT_MAP } from '../../data/redux/slices/leaderboardSlice';
import './Commanders.css';
import { getGrades } from '../../data/ScoreDisplayUtils';
import { Helmet } from 'react-helmet';
import { ResultsListing } from '../resultsListing/ResultsListing';
import { resultsColumns } from '../resultsListing/columns/ResultsColumns';
import { resultsCellRenderers } from '../resultsListing/renderers/ResultsCellRenderers';
import { resultsColumnRenderers } from '../resultsListing/renderers/ResultsColumnRenderers';
import isMobile from 'ismobilejs';
import {
    fetchCommanderDetailItems,
    setCommanderDetailsSortBy,
    setCommanderDetailsSortDirection,
} from '../../data/redux/slices/commanderDetailsSlice';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';

export function CommanderDetails() {
    const dispatch = useDispatch();
    const listItems = useSelector((state) => state?.commanderDetails?.listItems);
    const nextCursor = useSelector((state) => state?.commanderDetails?.nextCursor);
    const isFetching = useSelector((state) => state?.commanderDetails?.isFetching);
    const loadingState = useSelector((state) => state?.commanderDetails?.loadingState);
    const searchFilters = useSelector((state) => state?.commanderDetails?.filters);
    const details = useSelector((state) => state.commanderDetails?.details);
    const sortBy = useSelector((state) => state?.commanderDetails?.sortBy);

    const previewIsShowing = useSelector((state) => state.preview.isShowing);
    const mobile = isMobile(window.navigator).phone;

    const handleSortChange = (sortIndex) => {
        dispatch(setCommanderDetailsSortBy(SORT_MAP[sortIndex], searchFilters));
        dispatch(fetchCommanderDetailItems(null, true, SORT_MAP[sortIndex], sortDirection));
    };

    const sortDirection = useSelector((state) => state.commanderDetails.sortDirection);
    const handleSortDirectionChange = (direction) => {
        dispatch(setCommanderDetailsSortDirection(direction));
        dispatch(fetchCommanderDetailItems(null, true, sortBy, direction, searchFilters));
    };

    const handleLoadMore = () => {
        if (!isFetching && nextCursor !== null) {
            dispatch(fetchCommanderDetailItems(nextCursor, false, sortBy, sortDirection));
        }
    };

    let commanderNames = '';
    let commanderNamesOneLine = '';

    for (let i = 0; i < details?.commanders?.length; i++) {
        if (i > 0) {
            commanderNames = `${commanderNames}\n`;
            commanderNamesOneLine = `${commanderNamesOneLine} + `;
        }

        commanderNames = `${commanderNames}${details?.commanders?.[i]?.name}`;
        commanderNamesOneLine = `${commanderNamesOneLine}${details?.commanders?.[i]?.name}`;
    }

    const grades = details ? getGrades(details) : null;

    const leftPanelClassname = details?.commanders?.length > 1 ? 'leftPanelPartner' : 'leftPanelPartner';

    // const filteredListItems = (listItems || []).filter((item) => {
    //     return item !== null && item.hasOwnProperty('id');
    // });

    const getImageUri = (commander) => {
        if (commander?.metadata?.hasOwnProperty('imageUris')) {
            if (metadata?.image_uris?.border_crop) {
                return metadata?.image_uris?.border_crop;
            } else {
                return metadata?.card_faces?.[0]?.image_uris?.border_crop || '';
            }
        } else {
            return commander.faces[commander.frontFaceId].metadata.image_uris.border_crop;
        }
    };

    const helmetName = `Commanders > ${commanderNamesOneLine}`;
    const helmetCanonical = `https://www.commandersalt.com/commanders/id${details?.id}`;
    const helmetDescription = `MtG Commander decks using ${commanderNamesOneLine}`;
    const helmetTitle = `Commandersalt.com - MtG Commander decks using ${commanderNamesOneLine}`;

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            <Flex margin={10} direction={'row'} wrap justifyContent={'space-around'}>
                {/*<div className={leftPanelClassname}>*/}
                <Flex direction="row" justifyContent="center">
                    {details?.commanders?.map((commander) => {
                        return (
                            <img
                                key={`image_key__${commander.id}`}
                                src={getImageUri(commander)}
                                height={mobile ? 250 : 400}
                            />
                        );
                    })}
                </Flex>
                {/*</div>*/}
                <div className="rightPanel">
                    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <div className="commanderNamesTextContainer">
                            <Text UNSAFE_className="commanderNamesText">{commanderNames}</Text>
                        </div>
                        <View
                            width="100%"
                            maxWidth="size-6000"
                            alignSelf="center"
                            UNSAFE_className="commandersMetaBreakdownHeader"
                        >
                            <Text UNSAFE_className="commanderNamesText">
                                {`Meta breakdown for ${details?.count} deck${details?.count > 1 ? 's' : ''}`}
                            </Text>
                        </View>
                        <Flex direction="row" columnGap={10} justifyContent="center">
                            <Flex direction="column" alignItems="end">
                                <div className="metaFieldLabel">Power Level:</div>
                                <div className="metaFieldLabel">Saltiness:</div>
                                <div className="metaFieldLabel">Combo:</div>
                                <div className="metaFieldLabel">Synergy:</div>
                            </Flex>
                            {details !== null && (
                                <Flex direction="column">
                                    <div className="metaFieldLabel" style={{ color: grades?.['powerlevel']?.color }}>
                                        {parseFloat(details?.powerLevelRating)?.toFixed(1) || 0}
                                    </div>
                                    <div className="metaFieldLabel" style={{ color: grades?.['salt']?.color }}>
                                        {parseFloat(details?.saltRating).toFixed(1) || 0}
                                    </div>
                                    <div className="metaFieldLabel" style={{ color: grades?.['combo']?.color }}>
                                        {parseFloat(details?.comboRating).toFixed(1) || 0}
                                    </div>
                                    <div className="metaFieldLabel" style={{ color: grades?.['synergy']?.color }}>
                                        {parseFloat(details?.synergyRating).toFixed(1) || 0}
                                    </div>
                                </Flex>
                            )}
                        </Flex>
                    </div>
                </div>
            </Flex>
            <ResultsListing
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSortChange={handleSortChange}
                handleSortDirectionChange={handleSortDirectionChange}
                disableNavChanges={true}
                allowDeckPreview={true}
                handleSelectionChange={null}
                columns={resultsColumns.LEADERBOARD()}
                columnRenderer={resultsColumnRenderers.LEADERBOARD}
                listItems={listItems}
                cellRenderer={resultsCellRenderers.LEADERBOARD}
                previewIsShowing={previewIsShowing}
                handleLoadMore={handleLoadMore}
                loadingState={loadingState}
            />
        </Flex>
    );
}
