import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';
import { setSearchValue } from './searchSlice';

export const cardsSlice = createSlice({
    name: 'authors',
    initialState: {
        isFetching: false,
        loadingState: `idle`,
        currentCard: null,
    },
    reducers: {
        setCardsCurrentCard: (state, action) => {
            state.currentCard = action.payload;
            state.isFetching = false;
            state.loadingState = `idle`;
        },
        setCardsIsFetching: (state, action) => {
            state.isFetching = true;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCardsCurrentCard, setCardsIsFetching } = cardsSlice.actions;

export const fetchCardById = (id) => (dispatch) => {
    dispatch(setCardsCurrentCard(null));
    dispatch(setCardsIsFetching());

    DynamoConnector.getCardDetails(id, (results) => {
        dispatch(setCardsCurrentCard(results || null));
        // dispatch(setSearchValue({ list: [{ name: results.name, id: results.id }], field: 'cards' }));
        window.history.replaceState({}, null, `/cards/id/${id}`);
    });
};

export default cardsSlice.reducer;
