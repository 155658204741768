import {
    ActionButton,
    Checkbox,
    CheckboxGroup,
    Content,
    ContextualHelp,
    Dialog,
    DialogTrigger,
    Divider,
    Flex,
    Heading,
    Item,
    ListView,
    ProgressBar,
    Radio,
    RadioGroup,
    Text,
    View,
} from '@adobe/react-spectrum';
import { DownloadIcon } from 'react-modal-image/lib/icons';
import React from 'react';
import { getDomainPrefix } from '../../../data/DynamoConnector';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { setAppRoute } from '../../../data/redux/slices/appSlice';
import styles from './PrintDialog.css';

export function PrintDialog(props) {
    const { deckId, authorId, pageType, name } = props;

    let [selectedOptions, setSelectedOptions] = React.useState(['wincons', 'saltiness', 'background']);
    let [formatOptions, setFormatOptions] = React.useState(pageType === 'preview' ? 'png' : 'pdf');
    let [additionalCategories, setAdditionalCategories] = React.useState([]);
    let [isFetching, setIsFetching] = React.useState(false);

    const handleDownloadPress = async (closeMethod) => {
        setIsFetching(true);
        const options = {
            id: deckId || authorId,
            isAuthor: pageType === 'author',
            format: formatOptions,
            displayOptions: {},
        };

        selectedOptions.forEach((opt) => {
            options.displayOptions[opt] = true;
        });

        const url = `${getDomainPrefix()}/generateadvanced`;
        logEvent(getAnalytics(), `${pageType}_rule_0_click`);

        console.log(url);
        try {
            const startTime = performance.now();

            await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify(options),
            })
                .then((res) => res.blob())
                .then((data) => {
                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(data);
                    a.download = `Rule Zero - ${name}.${formatOptions}`;
                    a.click();
                });

            const endTime = performance.now();
            const responseTime = endTime - startTime;
            logEvent(getAnalytics(), 'generate_time', { timing: responseTime });

            setIsFetching(false);
            closeMethod();
        } catch (error) {
            //
            setIsFetching(false);
            logEvent(getAnalytics(), 'generate_failed');
        }
        // window.open(url);
    };

    return (
        <View
            borderWidth="thin"
            borderColor="light"
            borderRadius="medium"
            padding="size-50"
            backgroundColor="gray-75"
            minHeight="0px"
            maxWidth={400}
            alignSelf={'center'}
            marginTop={20}
        >
            <Flex direction="row" gap="size-100">
                <DialogTrigger isDismissable>
                    <ActionButton
                        // onPress={handleDownloadPress}
                        isDisabled={!(deckId || authorId)}
                        aria-label="Download"
                        isQuiet="true"
                        UNSAFE_style={{ cursor: 'pointer', paddingRight: 5 }}
                    >
                        <Flex direction={'row-reverse'}>
                            <Text UNSAFE_className="printDialogButton" marginStart={10}>
                                NEED A PRINTABLE "RULE ZERO" CARD?
                            </Text>
                            <DownloadIcon />
                        </Flex>
                    </ActionButton>
                    {(close) => (
                        <Dialog maxWidth={400}>
                            <Heading>RULE ZERO CARD</Heading>
                            <Divider />
                            <Content alignSelf={'center'} justifySelf={'center'}>
                                <Flex direction={'row'} columnGap={20}>
                                    {!isFetching ? (
                                        <>
                                            <Flex direction={'column'}>
                                                <RadioGroup
                                                    label="FORMAT"
                                                    value={formatOptions}
                                                    onChange={setFormatOptions}
                                                >
                                                    <Radio value="png" isDisabled={pageType !== 'preview'}>
                                                        PNG
                                                    </Radio>
                                                    <Radio value="pdf">PDF</Radio>
                                                </RadioGroup>
                                            </Flex>
                                            <Divider orientation="vertical" size={'S'} />
                                            <Flex direction={'column'} marginStart={10}>
                                                <CheckboxGroup
                                                    label="DISPLAY OPTIONS"
                                                    value={selectedOptions}
                                                    onChange={setSelectedOptions}
                                                >
                                                    <Checkbox value="background">BACKGROUND</Checkbox>
                                                    <Checkbox value="powerlevel">POWER LEVEL</Checkbox>
                                                    {/*<Checkbox value="archetype">ARCHETYPE</Checkbox>*/}
                                                    <Checkbox value="saltiness">SALTINESS</Checkbox>
                                                    <Checkbox value="wincons">WINCONS</Checkbox>
                                                    {/*<Checkbox value="basketball">Basketball</Checkbox>*/}
                                                </CheckboxGroup>
                                                {/*<ListView*/}
                                                {/*    selectionMode="multiple"*/}
                                                {/*    aria-label="ADDITIONAL CATEGORIES"*/}
                                                {/*    maxWidth="size-6000"*/}
                                                {/*>*/}
                                                {/*    <Item>Adobe Photoshop</Item>*/}
                                                {/*    <Item>Adobe InDesign</Item>*/}
                                                {/*    <Item>Adobe AfterEffects</Item>*/}
                                                {/*    <Item>Adobe Illustrator</Item>*/}
                                                {/*    <Item>Adobe Lightroom</Item>*/}
                                                {/*</ListView>*/}
                                            </Flex>
                                        </>
                                    ) : (
                                        <View
                                            marginTop={50}
                                            marginBottom={50}
                                            alignSelf="center"
                                            UNSAFE_style={{ verticalAlign: 'middle' }}
                                            maxWidth={600}
                                            justifySelf={'center'}
                                        >
                                            <ProgressBar
                                                label="GENERATING..."
                                                UNSAFE_style={{ width: '100%', maxWidth: 600 }}
                                                isIndeterminate
                                            />
                                        </View>
                                    )}
                                </Flex>
                                <Flex direction={'row-reverse'} columnGap={10} marginTop={30}>
                                    <ActionButton
                                        // onPress={handleDownloadPress}
                                        isDisabled={isFetching}
                                        aria-label="Download"
                                        onPress={(evn) => {
                                            handleDownloadPress(close);
                                        }}
                                        UNSAFE_style={{
                                            cursor: 'pointer',
                                            float: 'right',
                                            paddingRight: 10,
                                            marginTop: 20,
                                        }}
                                    >
                                        <Flex direction={'row-reverse'}>
                                            <Text UNSAFE_className="printDialogButton" marginStart={10}>
                                                DOWNLOAD
                                            </Text>
                                            <DownloadIcon />
                                        </Flex>
                                    </ActionButton>
                                    <ActionButton
                                        // onPress={handleDownloadPress}
                                        aria-label="Cancel"
                                        onPress={close}
                                        UNSAFE_style={{
                                            cursor: 'pointer',
                                            float: 'right',
                                            paddingRight: 10,
                                            marginTop: 20,
                                        }}
                                    >
                                        <Flex direction={'row-reverse'}>
                                            <Text UNSAFE_className="printDialogButton" marginStart={10}>
                                                CLOSE
                                            </Text>
                                        </Flex>
                                    </ActionButton>
                                </Flex>
                            </Content>
                        </Dialog>
                    )}
                </DialogTrigger>
            </Flex>
        </View>
    );
}
