import React from 'react';
import { ProgressBar } from '@adobe/react-spectrum';
import isMobile from 'ismobilejs';

export function ImportStatusBar() {
    const mobile = isMobile(window.navigator).phone;

    const maxWidth = mobile ? 'calc(100vw - 40px)' : '800px';

    return <ProgressBar label="Loading..." UNSAFE_style={{ width: '100%', maxWidth }} isIndeterminate />;
}
